// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useContext } from 'react';
// import { UserContext } from '../Api/context/AppContext';
// import user1 from "../../components/assets/no_image.png";
// import "../product/productdata/proctuct.css";

// const CartItem = ({ product, isGridView }) => {
//     const { addToCard, checkId } = useContext(UserContext);
//     const { mrp, short_description, prices, part_number ,parts_rating} = product;
//     const imagefilter = product.default?.attachment;
//     const { user } = useContext(UserContext);

//     console.log(product,"prodyuct")
//     // Ensure prices is an array and has at least one element
//     let currentprice = Array.isArray(prices) ? prices : [];
//     let extractedPrice;
//     let discount;

//     if (currentprice.length === 1) {
//         extractedPrice = currentprice[0].price;
//         discount = currentprice[0].discount;
//     } else {
//         extractedPrice = null;
//         discount = null;
//     }

//     const renderStars = () => {
//         const stars = [];
//         for (let i = 1; i <= 5; i++) {
//             stars.push(<i key={i} className="fas fa-star text-warning"></i>);
//         }
//         return stars;
//     };

//     return (
//         <>
//             {isGridView ?
//                 <div className='col-lg-4 cart_contain pb-1 '>
//                     <div className="card product-item mb-2 mt-1 p-2" style={{ borderRadius: '0', border: '1px solid rgb(212, 204, 204)', width: '300px', height: '320px' }}>
//                         <div className="card-header product-img position-relative overflow-hidden bg-transparent p-0" style={{ border: '1px solid rgb(212, 204, 204)', height: '150px' }}>
//                             {imagefilter ? (
//                                 <img className="" src={imagefilter} alt="" style={{ height: '100%' }} />
//                             ) : (
//                                 <img className="" src={user1} alt="" style={{ height: '100%' }} />
//                             )}
//                             <div className="overlay" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                             </div>
//                         </div>
//                         <div>
//                             <div className="card-body text-left p-0 pt-2 pb-2" style={{ marginLeft: '10px' }}>
//                                 <div className="d-flex justify-content-start align-items-center mb-1">
//                                     {renderStars()}
//                                 </div>
//                                 <p className="text-wrap-break-word text-dark" style={{ margin: 0, fontSize: '14px' }}>{part_number}</p>
//                                 <p className="mb-1 text-wrap-break-word no-wrap" style={{
//                                     whiteSpace: 'nowrap',
//                                     overflow: 'hidden',
//                                     textOverflow: 'ellipsis',
//                                     margin: 0,
//                                     fontSize: '12px'
//                                 }}>
//                                     {short_description}
//                                 </p>

//                                 <div className="d-flex justify-content-start align-items-center">
//                                     <h6 className="mr-2" style={{ margin: 0, fontSize: '14px' }}>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(mrp)}</h6>
//                                     <h6 style={{ margin: 0, fontSize: '12px' }}>{discount}</h6>
//                                 </div>
//                             </div>

//                             {user ? (
//                                 <div className="card-footer d-flex justify-content-between bg-light border-none p-1">
//                                     <button className='btn btn-sm footerbg p-2 px-2 rounded' style={{ fontSize: '12px' }}>
//                                         <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-light p-0"><i className="fas fa-eye text-light mr-1"></i>View Detail</Link>
//                                     </button>
//                                     {!checkId.includes(product.id) ? (
//                                         <button
//                                             onClick={() => addToCard(product, 1)}
//                                             className="btn btn-sm text-white p-2 px-2 rounded"
//                                             style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)', fontSize: '12px' }}
//                                         >
//                                             <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                             Add To Cart
//                                         </button>
//                                     ) : (
//                                         <button style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)', border: 'none', fontSize: '12px' }}>
//                                             <Link to="/home/cart" className="btn btn-sm p-0 text-white">
//                                                 <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                                 Go To Cart
//                                             </Link>
//                                         </button>
//                                     )}
//                                 </div>
//                             ) : (
//                                 <div className="d-flex justify-content-center bg-light mb-2">
//                                     <button className='btn btn-sm footerbg p-2 px-2 rounded' style={{ fontSize: '12px' }}>
//                                         <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-dark mr-1"></i>View Detail</Link>
//                                     </button>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 :
//                         <div className="card dev_list product-item mb-4" style={{ borderRadius: '0',marginRight: '70px' }}>
//                             <div className="row d-flex justify-content-around dev_list_alig" style={{paddingLeft: '30px', marginRight:'10px'}}>
//                                 <div className="col-lg-3 card-header product-img position-relative overflow-hidden bg-transparent p-0">
//                                     {imagefilter ? (
//                                         <img className="img-contain" src={imagefilter} alt="" />
//                                     ) : (
//                                         <img className="img-contain" src={user1} alt="" />
//                                     )}
//                                 </div>
//                                 <div className="col-lg-3 card-body text-left" >
//                                     <p className="mb-1 text-wrap-break-word no-wrap" style={{
//                                         whiteSpace: 'nowrap',
//                                         overflow: 'hidden',
//                                         textOverflow: 'ellipsis'
//                                     }}>
//                                         {short_description}
//                                     </p>
//                                     <p className="mb-1 text-wrap-break-word">{part_number}</p>
//                                 </div>
//                                 <div className="col-lg-3 d-flex justify-content-start align-items-center" >
//                                     <h6 className="mr-2">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(mrp)}</h6>
//                                     <h6>{discount}</h6>
//                                     <div className="d-flex justify-content-start align-items-center mb-3" style={{marginLeft: '30px'}}>
//                                     {renderStars()}
//                                 </div>
//                                 </div>
//                                 {user ? (
//                                     <div className="col-lg-3 d-flex justify-content-between bg-light border-none">
//                                         <button className='btn btn-sm footerbg p-2 px-2 rounded mr-3'>
//                                             <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-light p-0"><i className="fas fa-eye text-light mr-1"></i>View Detail</Link>
//                                         </button>
//                                         {!checkId.includes(product.id) ? (
//                                             <button
//                                                 onClick={() => addToCard(product, 1)}
//                                                 className="btn btn-sm text-white p-2 px-2 rounded"
//                                                 style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)' }}
//                                             >
//                                                 <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                                 Add To Cart
//                                             </button>
//                                         ) : (
//                                             <button style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)', border: 'none' }}>
//                                                 <Link to="/home/cart" className="btn btn-sm p-0 text-white">
//                                                     <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                                     Go To Cart
//                                                 </Link>
//                                             </button>
//                                         )}
//                                     </div>
//                                 ) : (
//                                     <div className="d-flex justify-content-center bg-light mb-2">
//                                         <button className='btn btn-sm footerbg p-2 px-2 rounded'>
//                                             <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-dark mr-1"></i>View Detail</Link>
//                                         </button>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//             }
//         </>
//     );
// };

// export default CartItem;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useContext } from 'react';
// import { UserContext } from '../Api/context/AppContext';
// import user1 from "../../components/assets/no_image.png";
// import "../product/productdata/proctuct.css";

// const CartItem = ({ product, isGridView }) => {
//     const { addToCard, checkId } = useContext(UserContext);
//     const { mrp, short_description, prices, part_number, parts_rating } = product;
//     const imagefilter = product.default?.attachment;
//     const { user } = useContext(UserContext);

//     console.log(product, "product");
//     // Ensure prices is an array and has at least one element
//     let currentprice = Array.isArray(prices) ? prices : [];
//     let extractedPrice;
//     let discount;

//     if (currentprice.length === 1) {
//         extractedPrice = currentprice[0].price;
//         discount = currentprice[0].discount;
//     } else {
//         extractedPrice = null;
//         discount = null;
//     }

//     const renderStars = (rating) => {
//         const stars = [];
//         for (let i = 1; i <= 5; i++) {
//             stars.push(
//                 <i key={i} className={i <= rating ? "fas fa-star text-warning" : "far fa-star text-warning"}></i>
//             );
//         }
//         return stars;
//     };

//     // Assuming parts_rating is the rating data you're getting from the API
//     const ratingData = product.parts_rating && product.parts_rating[0] ? product.parts_rating[0].rating : "0";
//     const rating = parseInt(ratingData, 10);  // Convert the rating to an integer

//     return (
//         <>
//             {isGridView ?
//                 <div className='col-lg-4 cart_contain pb-1 '>
//                     <div className="card product-item mb-2 mt-1 p-2" style={{ borderRadius: '0', border: '1px solid rgb(212, 204, 204)', width: '300px', height: '320px' }}>
//                         <div className="card-header product-img position-relative overflow-hidden bg-transparent p-0" style={{ border: '1px solid rgb(212, 204, 204)', height: '150px' }}>
//                             {imagefilter ? (
//                                 <img className="" src={imagefilter} alt="" style={{ height: '100%' }} />
//                             ) : (
//                                 <img className="" src={user1} alt="" style={{ height: '100%' }} />
//                             )}
//                             <div className="overlay" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                             </div>
//                         </div>
//                         <div>
//                             <div className="card-body text-left p-0 pt-2 pb-2" style={{ marginLeft: '10px' }}>
//                                 <div className="d-flex justify-content-start align-items-center mb-1">
//                                     {renderStars(rating)}
//                                 </div>
//                                 <p className="text-wrap-break-word text-dark" style={{ margin: 0, fontSize: '14px' }}>{part_number}</p>
//                                 <p className="mb-1 text-wrap-break-word no-wrap" style={{
//                                     whiteSpace: 'nowrap',
//                                     overflow: 'hidden',
//                                     textOverflow: 'ellipsis',
//                                     margin: 0,
//                                     fontSize: '12px'
//                                 }}>
//                                     {short_description}
//                                 </p>

//                                 <div className="d-flex justify-content-start align-items-center">
//                                     <h6 className="mr-2" style={{ margin: 0, fontSize: '14px' }}>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(mrp)}</h6>
//                                     <h6 style={{ margin: 0, fontSize: '12px' }}>{discount}</h6>
//                                 </div>
//                             </div>

//                             {user ? (
//                                 <div className="card-footer d-flex justify-content-between bg-light border-none p-1">
//                                     <button className='btn btn-sm footerbg p-2 px-2 rounded' style={{ fontSize: '12px' }}>
//                                         <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-light p-0"><i className="fas fa-eye text-light mr-1"></i>View Detail</Link>
//                                     </button>
//                                     {!checkId.includes(product.id) ? (
//                                         <button
//                                             onClick={() => addToCard(product, 1)}
//                                             className="btn btn-sm text-white p-2 px-2 rounded"
//                                             style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)', fontSize: '12px' }}
//                                         >
//                                             <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                             Add To Cart
//                                         </button>
//                                     ) : (
//                                         <button style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)', border: 'none', fontSize: '12px' }}>
//                                             <Link to="/home/cart" className="btn btn-sm p-0 text-white">
//                                                 <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                                 Go To Cart
//                                             </Link>
//                                         </button>
//                                     )}
//                                 </div>
//                             ) : (
//                                 <div className="d-flex justify-content-center bg-light mb-2">
//                                     <button className='btn btn-sm footerbg p-2 px-2 rounded' style={{ fontSize: '12px' }}>
//                                         <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-dark mr-1"></i>View Detail</Link>
//                                     </button>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 :
//                 <div className="card dev_list product-item mb-4" style={{ borderRadius: '0', marginRight: '70px' }}>
//                     <div className="row d-flex justify-content-around dev_list_alig" style={{ paddingLeft: '30px', marginRight: '10px' }}>
//                         <div className="col-lg-3 card-header product-img position-relative overflow-hidden bg-transparent p-0">
//                             {imagefilter ? (
//                                 <img className="img-contain" src={imagefilter} alt="" />
//                             ) : (
//                                 <img className="img-contain" src={user1} alt="" />
//                             )}
//                         </div>
//                         <div className="col-lg-3 card-body text-left">
//                             <p className="mb-1 text-wrap-break-word no-wrap" style={{
//                                 whiteSpace: 'nowrap',
//                                 overflow: 'hidden',
//                                 textOverflow: 'ellipsis'
//                             }}>
//                                 {short_description}
//                             </p>
//                             <p className="mb-1 text-wrap-break-word">{part_number}</p>
//                         </div>
//                         <div className="col-lg-3 d-flex justify-content-start align-items-center">
//                             <h6 className="mr-2">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(mrp)}</h6>
//                             <h6>{discount}</h6>
//                             <div className="d-flex justify-content-start align-items-center mb-3" style={{ marginLeft: '30px' }}>
//                                 {renderStars(rating)}
//                             </div>
//                         </div>
//                         {user ? (
//                             <div className="col-lg-3 d-flex justify-content-between bg-light border-none">
//                                 <button className='btn btn-sm footerbg p-2 px-2 rounded mr-3'>
//                                     <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-light p-0"><i className="fas fa-eye text-light mr-1"></i>View Detail</Link>
//                                 </button>
//                                 {!checkId.includes(product.id) ? (
//                                     <button
//                                         onClick={() => addToCard(product, 1)}
//                                         className="btn btn-sm text-white p-2 px-2 rounded"
//                                         style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)' }}
//                                     >
//                                         <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                         Add To Cart
//                                     </button>
//                                 ) : (
//                                     <button style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)', border: 'none' }}>
//                                         <Link to="/home/cart" className="btn btn-sm p-0 text-white">
//                                             <i className="fas fa-shopping-cart text-white mr-1"></i>
//                                             Go To Cart
//                                         </Link>
//                                     </button>
//                                 )}
//                             </div>
//                         ) : (
//                             <div className="d-flex justify-content-center bg-light mb-2">
//                                 <button className='btn btn-sm footerbg p-2 px-2 rounded'>
//                                     <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-dark mr-1"></i>View Detail</Link>
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             }
//         </>
//     );
// };

// export default CartItem;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../Api/context/AppContext";
import user1 from "../../components/assets/no_image.png";
import "../product/productdata/proctuct.css";

const CartItem = ({ product, isGridView, product_length }) => {
  const { addToCard, checkId } = useContext(UserContext);
  const { mrp, short_description, prices, part_number, parts_rating } = product;
  //   const imagefilter = product.default?.attachment;
  const test = false;
  // const imagefilter =
  //   product?.documents[0]?.part_pic === true
  //     ? product?.documents[0]?.attachment
  //     : null;
  const imagefilter =
    product?.documents.find((doc) => doc.part_pic === true)?.attachment || null;
  //   const imagefilter = test === true ? product?.documents[0]?.attachment : null;
  const { user } = useContext(UserContext);

  // console.log(product, "product");
  // Ensure prices is an array and has at least one element
  let currentprice = Array.isArray(prices) ? prices : [];
  let extractedPrice;
  let discount;

  if (currentprice.length === 1) {
    extractedPrice = currentprice[0].price;
    discount = currentprice[0].discount;
  } else {
    extractedPrice = null;
    discount = null;
  }

  // console.log(product?.parts_rating,"extractedrating for lenght ")
  const sortedReviews = product?.parts_rating;
  const lenghtreview = sortedReviews.length;

  let totalRating = 0;
  let totalReviews = sortedReviews.length;

  for (const review of sortedReviews) {
    totalRating += parseFloat(review.rating);
  }

  // Calculate average rating
  const averageRating =
    totalReviews > 0 ? (totalRating / totalReviews).toFixed(2) : 0;
  // console.log(averageRating, "average ratingusestate")

  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(averageRating);
    const halfStar = averageRating - fullStars >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<i key={`full-${i}`} className="fa fa-star text-warning"></i>);
    }

    if (halfStar) {
      stars.push(
        <i key="half" className="fa fa-star-half-alt text-warning"></i>
      );
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <i key={`empty-${i}`} className="fa fa-star text-secondary"></i>
      );
    }

    return stars;
  };

  useEffect(() => {
    console.log(product_length, "test_productlength");
    // This will force a re-render when product_length changes
  }, [product_length]);

  return (
    <>
      {/* {isGridView ? (
        <div className="col-lg-3 cart_contain pb-1 ">
          <div
            className="card product-item mb-2 mt-1 p-2"
            style={{
              borderRadius: "0",
              border: "1px solid rgb(212, 204, 204)",
              width: "270px",
              height: "320px",
            }}
          >
            {user ? (
              <div className=" d-flex justify-content-end bg-light border-none ">
              
                <Link
                  to={`/home/singleproduct/${product.part_number}`}
                  className="btn btn-sm "
                  style={{ color: "#1B6392" }}
                >
                  <i className="fas fa-eye  "></i>
                
                </Link>
                
                {!checkId.includes(product.id) ? (
                  <button
                    onClick={() => addToCard(product, 1)}
                    className="btn btn-sm p-1 px-2 rounded"
                    
                  >
                    <i className="fas fa-shopping-cart text-warning "></i>
                   
                  </button>
                ) : (
                 
                  <div className=" d-flex justify-content-end bg-light border-none ">
                    <Link
                      to="/home/cart"
                      className="btn btn-sm  text-white"
                    >
                      <i className="fas fa-shopping-cart text-warning mr-1"></i>
                    
                    </Link>
                  </div>
                 
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-end bg-light ">
                <Link to={`/home/singleproduct/${product.part_number}`}>
                  <i className="fas fa-eye" style={{ color: "#1B6392" }}></i>
                
                </Link>
              </div>
            )}
            <div
              className="card-header product-img position-relative overflow-hidden bg-transparent p-0 d-flex justify-content-center"
              style={{
                border: "1px solid rgb(212, 204, 204)",
                height: "150px",
              }}
            >
              {imagefilter ? (
                <img
                  className=""
                  src={imagefilter}
                  alt=""
                  style={{ height: "100%" }}
                />
              ) : (
                <img
                  className=""
                  src={user1}
                  alt=""
                  style={{ height: "100%" }}
                />
              )}
              <div
                className="overlay"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                className="card-body text-left p-0 pt-2 pb-2"
                style={{ marginLeft: "10px" }}
              >
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <span>{renderStars(Math.round(averageRating))}</span>{" "}
                  <span className="ml-2">{lenghtreview} Reviews</span>
                </div>

                <p
                  className="text-wrap-break-word text-dark"
                  style={{ margin: 0, fontSize: "14px" }}
                >
                  {part_number}
                </p>
                <p
                  className="mb-1 text-wrap-break-word no-wrap"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    margin: 0,
                    fontSize: "12px",
                  }}
                >
                  {short_description}
                </p>

                <div className="d-flex justify-content-start align-items-center">
                  <h6 className="mr-2" style={{ margin: 0, fontSize: "14px" }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(mrp)}
                  </h6>
                  <h6 style={{ margin: 0, fontSize: "12px" }}>{discount}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
       
        <div
          className="card dev_list product-item mb-4"
          style={{ borderRadius: "0", marginRight: "70px" }}
        >
          <div
            className="row d-flex justify-content-around align-items-center dev_list_alig"
            style={{ paddingLeft: "30px", marginRight: "10px" }}
          >
            <div className="col-lg-2 card-header product-img position-relative overflow-hidden bg-transparent p-0">
              <img className="img-contain" src={imagefilter || user1} alt="" />
            </div>
            <div className="col-lg-3 card-body text-left">
              <p
                className="mb-1 text-wrap-break-word no-wrap"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {short_description}
              </p>
              <p className="mb-1 text-wrap-break-word">{part_number}</p>
            </div>
            <div className="col-lg-3 d-flex justify-content-start align-items-center">
              <h6 className="mr-2">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(mrp)}
              </h6>
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginLeft: "20px" }}
              >
                {renderStars(Math.round(averageRating))}
           
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-end align-items-center">
              <Link
                to={`/home/singleproduct/${product.part_number}`}
                className="btn btn-sm p-2 px-2 rounded mr-2"
                style={{ color: "#1B6392" }}
              >
                <i className="fas fa-eye mr-1"></i>
               
              </Link>
              {!checkId.includes(product.id) ? (
                <button
                  onClick={() => addToCard(product, 1)}
                  className="btn btn-sm  p-2 px-2 rounded"
               
                >
                  <i className="fas fa-shopping-cart text-warning mr-1"></i>
                
                </button>
              ) : (
                <Link to="/home/cart" className="btn btn-sm  p-2 px-2 rounded">
                  <i className="fas fa-shopping-cart text-warning  mr-1"></i>
               
                </Link>
              )}
            </div>
          </div>
        </div>
      )} */}

      {/* {isGridView ? (
        <div
          className={
            product_length >= 3
              ? "col-lg-3 col-md-3 col-sm-6 cart_contain pb-1"
              : "cart_contain pb-1 mx-auto"
          }
          style={{ margin: product_length < 4 ? "18px" : "none" }}
        >
          <div
            className="card product-item mb-2 mt-1 p-2 "
            style={{
              margin: 0,
              borderRadius: "0",
              border: "1px solid rgb(212, 204, 204)",
              // width: "100%", // Changed to 100% for responsiveness
              width: product_length < 4 ? "280px" : "auto",
              height: "auto", // Changed to auto for responsiveness
            }}
          >
            {user ? (
              <div className="d-flex justify-content-end bg-light border-none ">
                <Link
                  to={`/home/singleproduct/${product.part_number}`}
                  className="btn btn-sm custom-text-color"
                >
                  <i className="fas fa-eye"></i>
                </Link>
                {!checkId.includes(product.id) ? (
                  <button
                    onClick={() => addToCard(product, 1)}
                    className="btn btn-sm p-1 px-2 rounded"
                  >
                    <i className="fas fa-shopping-cart text-warning "></i>
                  </button>
                ) : (
                  <Link to="/home/cart" className="btn btn-sm text-white">
                    <i className="fas fa-shopping-cart text-warning mr-1"></i>
                  </Link>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-end bg-light ">
                <Link to={`/home/singleproduct/${product.part_number}`}>
                  <i className="fas fa-eye custom-text-color"></i>
                </Link>
              </div>
            )}
            <div
              className="card-header product-img position-relative overflow-hidden bg-transparent p-0 d-flex justify-content-center"
              style={{
                border: "1px solid rgb(212, 204, 204)",
                height: "150px",
              }}
            >
              {imagefilter ? (
                <img
                  className="img-fluid" // Added img-fluid for responsiveness
                  src={imagefilter}
                  alt=""
                  style={{ height: "100%", objectFit: "cover" }} // Added objectFit for better image handling
                />
              ) : (
                <img
                  className="img-fluid" // Added img-fluid for responsiveness
                  src={user1}
                  alt=""
                  style={{ height: "100%", objectFit: "cover" }} // Added objectFit for better image handling
                />
              )}
            </div>
            <div>
              <div
                className="card-body text-left p-0 pt-2 pb-2"
                style={{ marginLeft: "10px" }}
              >
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <span>{renderStars(Math.round(averageRating))}</span>
                  <span className="ml-2">{lenghtreview} Reviews</span>
                </div>
                <p
                  className="text-wrap-break-word text-dark"
                  style={{ margin: 0, fontSize: "14px" }}
                >
                  {part_number}
                </p>
                <p
                  className="mb-1 text-wrap-break-word no-wrap"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    margin: 0,
                    fontSize: "12px",
                  }}
                >
                  {short_description}
                </p>
                <div className="d-flex justify-content-start align-items-center">
                  <h6 className="mr-2" style={{ margin: 0, fontSize: "14px" }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(mrp)}
                  </h6>
                  <h6 style={{ margin: 0, fontSize: "12px" }}>{discount}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="card dev_list product-item mb-4 mx-auto" // Added mx-auto for centering
          style={{ borderRadius: "0", marginRight: "0", width: "90%" }} // Changed marginRight to 0 and set width for responsiveness
        >
          <div
            className="row d-flex justify-content-center align-items-center dev_list_alig" // Changed justify-content-around to justify-content-center
            style={{ paddingLeft: "15px", marginRight: "10px" }} // Adjusted padding for responsiveness
          >
            <div className="col-lg-2 col-md-3 col-sm-4 card-header product-img position-relative overflow-hidden bg-transparent p-0">
              <img
                className="img-fluid" // Changed to img-fluid for responsiveness
                src={imagefilter || user1}
                alt=""
                style={{ height: "80px", objectFit: "cover" }} // Set a smaller height for the image
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-5 card-body text-left">
              <p
                className="mb-1 text-wrap-break-word no-wrap"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {short_description}
              </p>
              <p className="mb-1 text-wrap-break-word">{part_number}</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-start align-items-center">
              <h6 className="mr-2">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(mrp)}
              </h6>
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginLeft: "20px" }}
              >
                {renderStars(Math.round(averageRating))}
              </div>
            </div>
            <div className="col-lg-4 col-md-2 col-sm-12 d-flex justify-content-end align-items-center">
              <Link
                to={`/home/singleproduct/${product.part_number}`}
                className="btn btn-sm p-2 px-2 rounded mr-2"
                style={{ color: "#1B6392" }}
              >
                <i className="fas fa-eye mr-1"></i>
              </Link>
              {!checkId.includes(product.id) ? (
                <button
                  onClick={() => addToCard(product, 1)}
                  className="btn btn-sm p-2 px-2 rounded"
                >
                  <i className="fas fa-shopping-cart text-warning mr-1"></i>
                </button>
              ) : (
                <Link to="/home/cart" className="btn btn-sm p-2 px-2 rounded">
                  <i className="fas fa-shopping-cart text-warning mr-1"></i>
                </Link>
              )}
            </div>
          </div>
        </div>
      )} */}

      {/* 3 card error solve code ... */}
      {isGridView ? (
        <div className="m-1">
          <div
            className="card m-1 product-item p-2"
            style={{
              width: "250px",
              borderRadius: "0",
              border: "1px solid #d4cccc",
            }}
          >
            {user ? (
              <div className="d-flex justify-content-end bg-light border-none ">
                <Link
                  to={`/home/singleproduct/${product.part_number}`}
                  className="btn btn-sm custom-text-color"
                >
                  <i className="fas fa-eye"></i>
                </Link>
                {!checkId.includes(product.id) ? (
                  <button
                    onClick={() => addToCard(product, 1)}
                    className="btn btn-sm p-1 px-2 rounded"
                  >
                    <i className="fas fa-shopping-cart text-warning "></i>
                  </button>
                ) : (
                  <Link to="/home/cart" className="btn btn-sm text-white">
                    <i className="fas fa-shopping-cart text-warning mr-1"></i>
                  </Link>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-end bg-light ">
                <Link to={`/home/singleproduct/${product.part_number}`}>
                  <i className="fas fa-eye custom-text-color"></i>
                </Link>
              </div>
            )}
            <div
              className="card-header product-img position-relative overflow-hidden bg-transparent p-0 d-flex justify-content-center"
              style={{
                border: "1px solid rgb(212, 204, 204)",
                height: "150px",
                borderRadius: "0", // Added borderRadius: "0"
              }}
            >
              {imagefilter ? (
                <img
                  className="img-fluid" // Added img-fluid for responsiveness
                  src={imagefilter}
                  alt=""
                  style={{ height: "100%", objectFit: "cover" }} // Added objectFit for better image handling
                />
              ) : (
                <img
                  className="img-fluid" // Added img-fluid for responsiveness
                  src={user1}
                  alt=""
                  style={{ height: "100%", objectFit: "cover" }} // Added objectFit for better image handling
                />
              )}
            </div>
            <div>
              <div
                className="card-body text-left p-0 pt-2 pb-2"
                style={{ marginLeft: "10px" }}
              >
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <span>{renderStars(Math.round(averageRating))}</span>
                  <span className="ml-2">{lenghtreview} Reviews</span>
                </div>
                <p
                  className="text-wrap-break-word text-dark"
                  style={{ margin: 0, fontSize: "14px" }}
                >
                  {part_number}
                </p>
                <p
                  className="mb-1 text-wrap-break-word no-wrap"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    margin: 0,
                    fontSize: "12px",
                  }}
                >
                  {short_description}
                </p>
                <div className="d-flex justify-content-start align-items-center">
                  <h6 className="mr-2" style={{ margin: 0, fontSize: "14px" }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(mrp)}
                  </h6>
                  <h6 style={{ margin: 0, fontSize: "12px" }}>{discount}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="card dev_list product-item mb-4 mx-auto" // Added mx-auto for centering
          style={{ borderRadius: "0", marginRight: "0", width: "90%" }} // Changed marginRight to 0 and set width for responsiveness
        >
          <div
            className="row d-flex justify-content-center align-items-center dev_list_alig" // Changed justify-content-around to justify-content-center
            style={{ paddingLeft: "15px", marginRight: "10px" }} // Adjusted padding for responsiveness
          >
            <div className="col-lg-2 col-md-3 col-sm-4 card-header product-img position-relative overflow-hidden bg-transparent p-0">
              <img
                className="img-fluid" // Changed to img-fluid for responsiveness
                src={imagefilter || user1}
                alt=""
                style={{ height: "80px", objectFit: "cover" }} // Set a smaller height for the image
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-5 card-body text-left">
              <p
                className="mb-1 text-wrap-break-word no-wrap"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {short_description}
              </p>
              <p className="mb-1 text-wrap-break-word">{part_number}</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-start align-items-center">
              <h6 className="mr-2">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(mrp)}
              </h6>
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginLeft: "20px" }}
              >
                {renderStars(Math.round(averageRating))}
              </div>
            </div>
            <div className="col-lg-4 col-md-2 col-sm-12 d-flex justify-content-end align-items-center">
              <Link
                to={`/home/singleproduct/${product.part_number}`}
                className="btn btn-sm p-2 px-2 rounded mr-2"
                style={{ color: "#1B6392" }}
              >
                <i className="fas fa-eye mr-1"></i>
              </Link>
              {!checkId.includes(product.id) ? (
                <button
                  onClick={() => addToCard(product, 1)}
                  className="btn btn-sm p-2 px-2 rounded"
                >
                  <i className="fas fa-shopping-cart text-warning mr-1"></i>
                </button>
              ) : (
                <Link to="/home/cart" className="btn btn-sm p-2 px-2 rounded">
                  <i className="fas fa-shopping-cart text-warning mr-1"></i>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartItem;
