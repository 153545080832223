import React, { useState } from 'react';

function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [qaPairs] = useState([
    { question: "what is your name", answer: "My name is wikitek mechanic." },
    { question: "hi", answer: "hello,how can i help you" },
    { question: "how are you", answer: "I'm good, thank you!" },
    { question: "What can you do", answer: "I can provide information and answer your queries." },
    { question: "Where is my order", answer: "You can track your order in profile page ," },
    // Add more dynamic pairs of questions and answers here
  ]);

  const handleMessage = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'user', text: message },
    ]);

    const lowerCaseMessage = message.toLowerCase();

    const index = qaPairs.findIndex(
      (pair) => pair.question.toLowerCase() === lowerCaseMessage
    );

    const response =
      index !== -1 ? qaPairs[index].answer : "I don't understand.";

    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'wikitek helper', text: response },
      ]);
    }, 500);
  };

  const handleClearMessages = () => {
    setMessages([]);
  };

  return (
    <div className='row d-flex justify-content-start'>

      <div className='col-md-8 border border-dark footerbg text-white ' style={{ overflowY: 'scroll' }}>
        <h3 className='text-center text-info'>wikitek helper</h3>
        <hr></hr>
        <div className='chat-messages' style={{height:"300px"}}>
          {messages.map((message, index) => (
            <div key={index} style={{ textAlign: message.sender === 'bot' ? 'left' : 'right', margin: '5px' }}>
              <strong>{message.sender.toUpperCase()}:</strong> {message.text}
            </div>
          ))}
        </div>


        <div className='chat-input'>
          <input
            className='rounded w-100'
            type="text"
            placeholder="Type a message..."
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.target.value.trim() !== '') {
                handleMessage(e.target.value);
                e.target.value = '';
              }
            }}
          />
          <div className='d-flex justify-content-center'>
            <button className="ml-2 buttonbg text-white mt-2" onClick={handleClearMessages}>Clear Messages</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Chatbot;
