import React from 'react';
import './Loader.css'; // Create a corresponding CSS file for styling

const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}} >
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
