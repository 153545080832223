import React, { useState, useContext } from "react";
import axios from "axios";
import "../login/Login.css";
import { UserContext } from "../Api/context/AppContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import Footer from "../Footer";
import shop from "../login/images/shop.jpeg";
import Header from "../Header";
import Register from "../register/Register";
// import OrderSingledata from '../orders/OrderSingledata';

function LoginPage() {
  const { autho, setautho, setUser, setToken } = useContext(UserContext);

  const navigate = useNavigate();
  const [Login, setLogin] = useState("");
  const [showreg, setshowreg] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    const payload = {
      username: email,
      password: password,
    };

    e.preventDefault();
    try {
      const response = await axios.post(
        "https://admin.inventab.io/api/v1/accounts/login",
        payload
      );

      const EmailAmit = localStorage.setItem("emailData", email);
      const PasswordAmit = localStorage.setItem("PasswordAmit", password);
      const LoginResponse = response.data;
      console.log(
        LoginResponse.data,
        "Successlogi*****************************"
      );
      // Adjust the API endpoint as needed
      // setLogin(LoginResponse)
      console.log("Login successful****************", response.data);
      let token = response?.data?.data?.auth_token.access;
      let user_id = response.data?.data?.user_id;
      localStorage.setItem(
        "LoginResponse",
        JSON.stringify(LoginResponse?.data)
      );
      localStorage.setItem("token", token);
      setToken(token);
      setautho(true);
      localStorage.setItem("user", response?.data?.data.user_id);
      localStorage.setItem("orgid", response?.data?.data.org.id);

      localStorage.setItem(
        "profile",
        JSON.stringify({
          email: response.data.data.email,
          name: response.data.data.first_name,
          mobile: response.data.data.mLoginobile,
        })
      );

      localStorage.setItem(
        "make",
        response.data.data.marketplace[0].marketplace_name
      );

      localStorage.setItem(
        "marketplace_nameId",
        response?.data?.data?.marketplace[0].id
      );

      setUser(user_id);
      setEmail("");
      setPassword("");

      setTimeout(() => {
        navigate("/home");
      }, 1000);

      // Redirect the user to another page or perform additional actions upon successful login
    } catch (error) {
      setError(error?.response?.data?.message);
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="login-page">
      {/* <div
        style={{
          width: "100%",
          padding: "10px",
          textAlign: "center",
        }}
      > */}
      <Header />
      {/* </div> */}

      {/* <div className="container">
        <div className="row p-5 justify-content-between">
          <div className="col-md-6 shop-image">
            <img src={shop} alt="Shopping" className="" />
          </div>
          <div className="col-md-6 login-container">
            <h1 className="login-title mt-4 mb-5">LOGIN</h1>
            <form onSubmit={handleLogin} className="login-form">
              <label className="login-label">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="me@example.com"
                className="login-input"
              />

              <label className="login-label">Password</label>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="••••••••••"
                  className="login-input"
                />
                <div
                  className="password-toggle-icon"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              <button type="submit" className="login-button">
                Login
              </button>

              <p>
                <Link to="/forget-pass">Forgot Password</Link>
              </p>
              <h6>Don't have an account? </h6>
              <Link
                to="/register"
                style={{ textDecoration: "none", color: "#0056b3" }}
              >
                <button
                  className="register-btn w-100"
                  style={{
                    border: "1px solid #0056b3",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Register
                </button>
              </Link>
            </form>
            {error && <p className="login-error">{error}</p>}
          </div>
         
        </div>
      </div> */}

      <div className="container login-box-custom">
        <div className="row p-5 justify-content-center">
          {/* <div className="col-md-6 col-lg-5 shop-image">
           
            <img src={shop} alt="Shopping" className="img-fluid" />{" "}
            
          </div> */}
          <div className="col-md-6 col-lg-5 login-container ">
            {/* <h1 className=" mt-4 mb-5 custom-text-color">LOGIN</h1> */}
            <button
              className="btn"
              onClick={() => setshowreg(false)}
              // style={{ color: showreg ? "black" : "#fa8232" }}
              style={{
                color: showreg ? "black" : "#fa8232",
                // textDecoration: showreg ? "none" : "underline",
                fontWeight: showreg ? "normal" : "bold", // Bold when not selected
                borderBottom: showreg ? "none" : "2px solid #fa8232", // Underline effect
                transition: "all 0.3s ease", // Smooth transition
              }}
            >
              Login
            </button>
            <button
              className="btn"
              onClick={() => setshowreg(true)}
              // style={{ color: !showreg ? "black" : "#fa8232" }}
              style={{
                color: !showreg ? "black" : "#fa8232",
                // textDecoration: showreg ? "none" : "underline",
                fontWeight: !showreg ? "normal" : "bold", // Bold when not selected
                borderBottom: !showreg ? "none" : "2px solid #fa8232", // Underline effect
                transition: "all 0.3s ease", // Smooth transition
              }}
            >
              Register
            </button>
            {!showreg ? (
              <form onSubmit={handleLogin} className="login-form mt-4">
                <label className="login-label">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="me@example.com"
                  className="login-input"
                />

                <label className="login-label">Password</label>
                <div className="password-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="••••••••••"
                    className="login-input"
                  />
                  <div
                    className="password-toggle-icon"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>

                <button type="submit" className=" btn text-white font-weight-bold w-100 footerbg">
                  Login
                </button>

                <p>
                  <Link to="/forget-pass">Forgot Password</Link>
                </p>
                {/* <h6>Don't have an account? </h6> */}
                {/* <Link
                to="/register"
                style={{ textDecoration: "none", color: "#0056b3" }}
              >
                <button
                  className="register-btn w-100" // Added w-100 for full-width button
                  style={{
                    border: "1px solid #0056b3",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Register
                </button>
              </Link> */}
              </form>
            ) : (
              // <>test</>
              <Register />
            )}
            {error && <p className="login-error">{error}</p>}
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* <div
        style={{
          marginTop: "auto",
          width: "100%",
          padding: "10px", // Adjust as needed
          textAlign: "center",
        }}
      > */}
      <Footer />

      {/* </div> */}
    </div>
  );
}

export default LoginPage;

// import React, { useState, useContext } from "react";
// import axios from "axios";
// import "../login/Login.css";
// import { UserContext } from "../Api/context/AppContext";
// import { Link } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
// import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
// import Footer from "../Footer";
// import shop from '../login/images/shop.jpeg';
// import Header from "../Header";

// function LoginPage() {
//   const { autho, setautho, setUser, setToken } = useContext(UserContext);

//   const navigate = useNavigate();

//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const [error, setError] = useState("");
//   const [showPassword, setShowPassword] = useState(false);

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//   };

//   const handleTogglePassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleLogin = async (e) => {
//     const payload = {
//       username: email,
//       password: password,
//     };

//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "http://143.244.142.0/api/v1/accounts/login",
//         payload
//       );

//       const EmailAmit = localStorage.setItem("emailData", email);
//       const PasswordAmit = localStorage.setItem("PasswordAmit", password);
//       const LoginResponse = response.data;

//       // Adjust the API endpoint as needed

//       console.log("Login successful****************", response.data);
//       let token = response.data.data.auth_token.access;
//       let user_id = response.data.data.user_id;

//       localStorage.setItem("token", token);
//       setToken(token);
//       setautho(true);
//       localStorage.setItem("user", response.data.data.user_id);
//       localStorage.setItem("orgid", response.data.data.org.id);

//       localStorage.setItem("profile", JSON.stringify({
//         email: response.data.data.email,
//         name: response.data.data.first_name,
//         mobile: response.data.data.mobile,
//       }));

//       localStorage.setItem(
//         "make",
//         response.data.data.marketplace[0].marketplace_name
//       );

//       localStorage.setItem("marketplace_nameId", response.data.data.marketplace[0].id);

//       setUser(user_id);
//       setEmail("");
//       setPassword("");

//       setTimeout(() => {
//         navigate('/home');
//       }, 1000);

//       // Redirect the user to another page or perform additional actions upon successful login
//     } catch (error) {
//       setError(error.response.data.message);
//       console.error("Login failed:", error);
//     }
//   };

//   return (
//     <div className="login-page">
//       <div style={{
//         width: '100%',
//         padding: '10px',
//         textAlign: 'center'
//       }}>
//         <Header />
//       </div>
//       <div className="row" style={{ marginTop: "85px", marginBottom: "85px" }}>
//         <div className="col-md-6 shop-image">
//           <img src={shop} alt="Shopping" className="" />
//         </div>
//         <div className="col-md-6 login-container">
//           <h1 className="login-title mt-4 mb-5">LOGIN</h1>
//           <form onSubmit={handleLogin} className="login-form">
//             <label className="login-label">Email Address</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={email}
//               onChange={handleEmailChange}
//               placeholder="me@example.com"
//               className="login-input"
//             />

//             <label className="login-label">Password</label>
//             <div className="password-input-container">
//               <input
//                 type={showPassword ? 'text' : 'password'}
//                 id="password"
//                 name="password"
//                 value={password}
//                 onChange={handlePasswordChange}
//                 placeholder="••••••••••"
//                 className="login-input"
//               />
//               <div className="password-toggle-icon" onClick={handleTogglePassword}>
//                 {showPassword ? <FaEyeSlash /> : <FaEye />}
//               </div>
//             </div>

//             <button type="submit" className="login-button">
//               Login
//             </button>

//             <p><Link to="/forget-pass">Forgot Password</Link></p>
//             <h6>Don't have an account? </h6>
//             <button className="register-btn" style={{ border: '1px solid #0056b3', padding: '5px 10px', borderRadius: '5px' }}>
//               <Link to="/register" style={{ textDecoration: 'none', color: '#0056b3' }}>Register</Link>
//             </button>
//           </form>
//           {error && <p className="login-error">{error}</p>}
//         </div>
//         {/* <Footer /> */}
//       </div>
//       <div style={{
//         marginTop: 'auto',
//         width: '100%',
//         padding: '10px', // Adjust as needed
//         textAlign: 'center'
//       }}>
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default LoginPage;
