import React from "react";
import ProductCard from "./ProductCard";
import Slider from "react-slick";
const ProductContainer = ({ product, heading }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust number of visible cards
    slidesToScroll: 1,
  };
  return (
    // <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
    //     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>

    //         {product.map((product, index) => (
    //             <ProductCard key={index} item={product} />
    //         ))}
    //     </div>
    // </div>
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      {" "}
      {/* Changed nowrap to wrap and added justifyContent */}
      {product.map((product, index) => (
        <div
        className="w-100"
          style={{
            
            flex: "1 1 100%", // Changed to 100% for mobile
            maxWidth: window.innerWidth < 768 ? "100%" : "250px", // Conditional maxWidth based on screen size
          }}
          key={index}
        >
          {" "}
          {/* Adjusted flex property for responsiveness */}
          <ProductCard item={product} />
        </div>
      ))}
    </div>
  );
};

export default ProductContainer;
