import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./Api/context/AppContext";

// import logodeafault from "../components/assets/logowikitek.png";
import logodeafault from "../components/assets/enata-logo.png";

import Categorieslist from "./Categorieslist";

function Header() {
  const { user, handleLogout, cartlen } = useContext(UserContext);
  const [logo, setLogo] = useState(logodeafault);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  const [visibleSection, setVisibleSection] = useState(null);
  useEffect(() => {
    const userNameAmit = localStorage.getItem("emailData");
    const UserPassword = localStorage.getItem("PasswordAmit");

    if (user && !isLogoLoaded) {
      axios
        .post("https://admin.inventab.io/api/v1/accounts/login", {
          username: userNameAmit,
          password: UserPassword,
        })
        .then((response) => {
          const { data } = response;
          if (data && data.success) {
            const organization = data.data.org;
            if (organization && organization.logo) {
              // setLogo(`http://143.244.142.0${organization.logo}`);
              setIsLogoLoaded(true); // Set the flag to prevent further logo updates
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching login data:", error);
        });
    }
  }, [user, isLogoLoaded]);

  return (
    <>
    {/* old code start  */}
      {/* 
      <div className="container-fluid d-flex justify-content-between footerbg align-items-center mb-2 pt-3 pb-3 navtopbar" >
     
        <div className="d-flex  logodiv">
        
          {user ? (

            <Link to="/home" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold ">

                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>
          ) : (
            <Link to="/" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold ">

                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>

          )}
        </div>
        <div className="buttondiv">
          {user ? (

            <Link to="/home/orderhistory" className="btn  text-light">
              <span className="fs-1"><i class="fas fa-solid fa-user shake-icon"></i> </span>
            </Link>

          ) : (
            <Link ></Link>


          )}

          {user ? (

            <Link to="/home/cart" className="btn   text-light ">
              <i className="fas fa-shopping-cart shake-icon"></i>
              {!!cartlen && <span className=""> {cartlen}</span>}
            </Link>

          ) : (
            <Link ></Link>


          )}


          {user ? (

            <span
              onClick={handleLogout} >
              <Link to="/login" className="text-light"><i class="fas fa-sign-out-alt"></i></Link>
            </span>

          ) : (
            <button className="rounded p-2" style={{width:"100px"}}>
              <Link to="/login" style={{ textDecoration: 'none'}} > Login</Link>
            </button>



          )}
        </div>

      </div>

      {user ? (

        <div className="d-flex  border border-gray mb-1 mt-0">
          <div>
            <button className="mr-2 btn">
              <Link to="/home/orderhistory" className="d-flex align-items-center text-dark" style={{textDecoration: 'none'}}>
                <i className="fas fa-map-marker-alt pr-2"></i>
                <p className="m-0">Track Order</p>
              </Link>
            </button>

            <button
              className="mr-2 btn"
              onClick={() => setVisibleSection(visibleSection === 'support' ? null : 'support')}
            >
              <i className="fas fa-headphones text-dark pr-2"></i>
              Customer support
            </button>

            <button
              className="mr-2 btn"
              onClick={() => setVisibleSection(visibleSection === 'faq' ? null : 'faq')}
            >
              <i className="fas fa-question-circle text-dark pr-2"></i>
              Need Help
            </button>

           
          </div>

          <div className="ml-auto mt-0 d-flex align-items-center pr-3">
            {visibleSection === 'support' && <div>
            <p className="mb-1 text-dark"><i class="fa fa-envelope text-dark mr-3"></i>support@wikitek.in</p>
            <p className="mb-1 text-dark"><i class="fa fa-phone-alt text-dark mr-3"></i>+91 9028347071</p>
              </div>}

            {visibleSection === 'vehicle' && (
              <div>
                <button className="border-0 mr-5 rounded">Model</button>
                <button className="border-0 mr-5 rounded">Sub Model</button>
                <button className="border-0 mr-5 rounded">Year</button>
              </div>
            )}

            {visibleSection === 'faq' && <Link class="text-dark mr-3" to="/home/faq">FAQs</Link>}
          </div>


        </div>

      ) : (
        null

      )} */}

      {/* old code end  */}

      {/* new code start  */}
      {/* <div className="container-fluid d-flex flex-column flex-md-row justify-content-between footerbg  mb-2 pt-3 pb-3 navtopbar">
        <div className="d-flex logodiv mb-2 mb-md-0">
          {user ? (
            <Link to="/home" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold">
                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>
          ) : (
            <Link to="/" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold">
                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>
          )}
        </div>
        <div className="buttondiv d-flex flex-wrap justify-content-center">
          {user ? (
            <Link to="/home/orderhistory" className="btn text-light mx-1">
              <span className="fs-1">
                <i className="fas fa-solid fa-user shake-icon"></i>
              </span>
            </Link>
          ) : null}

          {user ? (
            <Link to="/home/cart" className="btn text-light mx-1">
              <i className="fas fa-shopping-cart shake-icon"></i>
              {!!cartlen && <span className=""> {cartlen}</span>}
            </Link>
          ) : null}

          {user ? (
            <span onClick={handleLogout}>
              <Link to="/login" className=" btn text-light mx-1 ">
                <i className="fas fa-sign-out-alt"></i>
              </Link>
            </span>
          ) : (
            <button className="rounded p-2 mx-1" style={{ width: "100px" }}>
              <Link to="/login" style={{ textDecoration: "none" }}>
                {" "}
                Login
              </Link>
            </button>
          )}
        </div>
      </div>
      

      {user ? (
        <div className="d-flex flex-column flex-md-row border border-gray mb-1 mt-0">
          <div className="d-flex flex-wrap">
            <button className="mr-2 btn">
              <Link
                to="/home/orderhistory"
                className="d-flex align-items-center text-dark"
                style={{ textDecoration: "none" }}
              >
                <i className="fas fa-map-marker-alt pr-2"></i>
                <p className="m-0">Track Order</p>
              </Link>
            </button>

            <button
              className="mr-2 btn"
              onClick={() =>
                setVisibleSection(
                  visibleSection === "support" ? null : "support"
                )
              }
            >
              <i className="fas fa-headphones text-dark pr-2"></i>
              Customer support
            </button>

            <button
              className="mr-2 btn"
              onClick={() =>
                setVisibleSection(visibleSection === "faq" ? null : "faq")
              }
            >
              <i className="fas fa-question-circle text-dark pr-2"></i>
              Need Help
            </button>
          </div>

          <div className="ml-auto mt-0 d-flex align-items-center pr-3">
            {visibleSection === "support" && (
              <div>
                <p className="mb-1 text-dark">
                  <i className="fa fa-envelope text-dark mr-3"></i>
                  support@wikitek.in
                </p>
                <p className="mb-1 text-dark">
                  <i className="fa fa-phone-alt text-dark mr-3"></i>+91
                  9028347071
                </p>
              </div>
            )}

            {visibleSection === "vehicle" && (
              <div>
                <button className="border-0 mr-5 rounded">Model</button>
                <button className="border-0 mr-5 rounded">Sub Model</button>
                <button className="border-0 mr-5 rounded">Year</button>
              </div>
            )}

            {visibleSection === "faq" && (
              <Link className="text-dark mr-3" to="/home/faq">
                FAQs
              </Link>
            )}
          </div>
        </div>
      ) : null} */}

      <div className="container-fluid d-flex flex-column flex-md-row justify-content-md-between justify-content-center footerbg mb-2 pt-3 pb-3 navtopbar">
        <div className="d-flex justify-content-center mb-2 mb-md-0">
          {user ? (
            <Link to="/" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold">
                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>
          ) : (
            <Link to="/" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold">
                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {user ? (
            <Link to="/home/orderhistory" className="btn text-light mx-1">
              <span className="fs-1">
                <i className="fas fa-solid fa-user shake-icon"></i>
              </span>
            </Link>
          ) : null}

          {user ? (
            <Link to="/home/cart" className="btn text-light mx-1">
              <i className="fas fa-shopping-cart shake-icon"></i>
              {!!cartlen && (
                <span className="d-none d-md-inline"> {cartlen}</span>
              )}{" "}
              {/* Show cart length only on medium screens and up */}
            </Link>
          ) : null}

          {user ? (
            <span onClick={handleLogout}>
              <Link to="/login" className="btn text-light mx-1">
                <i className="fas fa-sign-out-alt"></i>
              </Link>
            </span>
          ) : (
            <button className="rounded p-2 mx-1 custom-text-color" style={{ width: "100px" }}>
              <Link to="/login" className="custom-text-color" style={{ textDecoration: "none" }}>
                Login
              </Link>
            </button>
          )}
        </div>
      </div>

      {user ? (
        // <div className="d-flex flex-column flex-md-row border border-gray mb-1 mt-0">
        //   <div className="d-flex flex-wrap">
        //     <button className="mr-2 btn">
        //       <Link
        //         to="/home/orderhistory"
        //         className="d-flex align-items-center text-dark"
        //         style={{ textDecoration: "none" }}
        //       >
        //         <i className="fas fa-map-marker-alt pr-2"></i>
        //         <p className="m-0">Track Order</p>
        //       </Link>
        //     </button>

        //     <button
        //       className="mr-2 btn"
        //       onClick={() =>
        //         setVisibleSection(
        //           visibleSection === "support" ? null : "support"
        //         )
        //       }
        //     >
        //       <i className="fas fa-headphones text-dark pr-2"></i>
        //       Customer support
        //     </button>

        //     <button
        //       className="mr-2 btn"
        //       onClick={() =>
        //         setVisibleSection(visibleSection === "faq" ? null : "faq")
        //       }
        //     >
        //       <i className="fas fa-question-circle text-dark pr-2"></i>
        //       Need Help
        //     </button>
        //   </div>

        //   <div className="ml-auto mt-0 d-flex align-items-center pr-3">
        //     {visibleSection === "support" && (
        //       <div className="d-flex flex-column">
        //         <p className="mb-1 text-dark">
        //           <i className="fa fa-envelope text-dark mr-3"></i>
        //           support@wikitek.in
        //         </p>
        //         <p className="mb-1 text-dark">
        //           <i className="fa fa-phone-alt text-dark mr-3"></i>+91
        //           9028347071
        //         </p>
        //       </div>
        //     )}

        //     {visibleSection === "vehicle" && (
        //       <div className="d-flex flex-column">
        //         <button className="border-0 mr-5 rounded">Model</button>
        //         <button className="border-0 mr-5 rounded">Sub Model</button>
        //         <button className="border-0 mr-5 rounded">Year</button>
        //       </div>
        //     )}

        //     {visibleSection === "faq" && (
        //       <Link className="text-dark mr-3" to="/home/faq">
        //         FAQs
        //       </Link>
        //     )}
        //   </div>
        // </div>
        <div className="d-flex flex-column flex-md-row border border-gray mb-1 mt-0">
        <div className="d-flex flex-column flex-md-row">
          <button className="mr-2 d-flex btn">
            {/* <Link
              to="/home/orderhistory"
              className="d-flex align-items-center text-dark"
              style={{ textDecoration: "none" }}
            > */}
            <i className="fas fa-map-marker-alt pr-2 text-dark" onClick={() => window.location.href = "/home/orderhistory"}></i>
            <p className="m-0"  onClick={() => window.location.href = "/home/orderhistory"}>Track Order</p>
            {/* </Link> */}
          </button>

          <button
            className="mr-2 btn d-flex"
            onClick={() =>
              setVisibleSection(
                visibleSection === "support" ? null : "support"
              )
            }
          >
            <i className="fas fa-headphones text-dark pr-2"></i>
            Customer support
          </button>

          <button
            className="mr-2 btn d-flex"
            onClick={() =>
              setVisibleSection(visibleSection === "faq" ? null : "faq")
            }
          >
            <i className="fas fa-question-circle text-dark pr-2"></i>
            Need Help
          </button>
        </div>

        <div className="ml-auto mt-0 d-flex align-items-center pr-3">
          {visibleSection === "support" && (
            <div className="d-flex flex-column">
              <p className="mb-1 text-dark">
                <i className="fa fa-envelope text-dark mr-3"></i>
                support@wikitek.in
              </p>
              <p className="mb-1 text-dark">
                <i className="fa fa-phone-alt text-dark mr-3"></i>+91
                9028347071
              </p>
            </div>
          )}

          {visibleSection === "vehicle" && (
            <div className="d-flex flex-column">
              <button className="border-0 mr-5 rounded">Model</button>
              <button className="border-0 mr-5 rounded">Sub Model</button>
              <button className="border-0 mr-5 rounded">Year</button>
            </div>
          )}

          {visibleSection === "faq" && (
            <Link className="text-dark mr-3" to="/home/faq">
              FAQs
            </Link>
          )}
        </div>
      </div>
      ) : null}
      {/* new code end  */}
    </>
  );
}

export default Header;
