import React, { useEffect, useState } from "react";
import { UserContext } from "./Api/context/AppContext";
import CategoLink from "./categories/CategoLink";
import { useQuery } from "react-query";
import axios from "axios";
import { useContext } from "react";
import Loader from "./Loader";
function Categorieslist({ view }) {
  console.log("view:>>>", view);
  const fetchData = async () => {
    const storedMarket = localStorage.getItem("make");
    try {
      // const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?market_place=enata-automotive`);
      const response = await axios.get(
        `https://admin.inventab.io/api/v1/parts/fetch/parts/?market_place=enata-automotive`
      );

      return response.data.results;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading, isError, error } = useQuery(
    ["menu-data"],
    fetchData
  );

  return (
    // <div className="col-md-12 d-none d-lg-block">
    //   <h5 className="font-weight-semi-bold mb-4">Categories</h5>

    //   {/* <CategoLink data={data} view={view} /> */}
    //   {data && data.length > 0 ? (
    //     <CategoLink data={data} view={view} />
    //   ) : (
    //     <div
    //       className="d-flex justify-content-center "

    //     >
    //       <div className="loader"></div>
    //     </div>
    //   )}
    // </div>

    <>
      <div className="col-12 d-block d-lg-none">
        <h5 className="font-weight-semi-bold mb-4">Categories</h5>

        {data && data.length > 0 ? (
          <CategoLink data={data} view={view} />
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
      <div className="col-md-12 d-none d-lg-block">
        <h5 className="font-weight-semi-bold mb-4">Categories</h5>

        {data && data.length > 0 ? (
          <CategoLink data={data} view={view} />
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  );
}

export default Categorieslist;
