// TabComponent.jsx
import React, { useContext, useState, useEffect } from "react";
import "./TabComponent.css";
import History from "./History";
import Footer from "./Footer";
import { useQuery } from "react-query";
import axios from "axios";
import { UserContext } from "./Api/context/AppContext";
import OrderSingledata from "./orders/OrderSingledata";
import { Link, useParams } from "react-router-dom";
import ViewOrderDetails from "./orders/ViewOrderDetails";

function TabComponent() {
  const [orderdata, setsingledata] = useState();
  const [activeTab, setActiveTab] = useState("v-pills-home");

  const { token } = useContext(UserContext);
  const userId = localStorage.getItem("user");

  const fetchData = async (userId, token) => {
    const response = await axios.get(
      `https://admin.inventab.io/api/v1/marketplace/marketplace-orders/?user=${userId}`,
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
    return response.data.results;
  };

  const { data, error, isLoading } = useQuery(
    ["marketplaceOrders", userId, token],
    () => fetchData(userId, token)
  );

  const SingleorderData = async (orderId) => {
    try {
      const response = await axios.get(
        `https://admin.inventab.io/api/v1/marketplace/marketplace-orders/?id=${orderId}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      setsingledata(response.data.results[0]);
      setActiveTab("v-pills-profile"); // Move to the profile tab
    } catch (error) {
      console.error(error.message, "______________error");
    }
  };

  useEffect(() => {
    SingleorderData();
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div
        className="d-flex mt-2"
        style={{ backgroundColor: "rgba(128, 113, 113, 0.2)" }}
      >
        <div
          className="top_route justify-content-start"
          style={{ marginLeft: "70px" }}
        >
          <>
            <Link to="/home" className="btn_top">
              <i className="fas fa-home text-dark mr-1"></i> Home /
            </Link>
            Order History
          </>
        </div>
      </div>

      {/* <section className="order_page">
        <div className="container-fluid">
          <div className="row tab_bor justify-content-center">
            <div className="col-12 text-center mt-5">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className={`tab-pane fade ${activeTab === 'v-pills-home' ? 'show active' : ''} text-center`}
                  id="v-pills-home"
                  role="tabpanel"
                >

                  <History data={data} isLoading={isLoading} error={error} SingleorderData={SingleorderData} />
                </div>

                <div
                  className={`tab-pane fade ${activeTab === 'v-pills-profile' ? 'show active' : ''}`}
                  id="v-pills-profile"
                  role="tabpanel"
                >
                  <OrderSingledata OrderSigledata={orderdata} />
                </div>

                <div
                  className={`tab-pane fade ${activeTab === 'v-pills-settings' ? 'show active' : ''}`}
                  id="v-pills-settings"
                  role="tabpanel"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="order_page">
        <div className="container-fluid">
          <div className="row tab_bor justify-content-center">
            <div className="col-12 text-center mt-5">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "v-pills-home" ? "show active" : ""
                  } text-center`}
                  id="v-pills-home"
                  role="tabpanel"
                >
                  <History
                    data={data}
                    isLoading={isLoading}
                    error={error}
                    SingleorderData={SingleorderData}
                  />
                </div>

                <div
                  className={`tab-pane fade ${
                    activeTab === "v-pills-profile" ? "show active" : ""
                  }`}
                  id="v-pills-profile"
                  role="tabpanel"
                >
                  <OrderSingledata OrderSigledata={orderdata} />
                </div>

                <div
                  className={`tab-pane fade ${
                    activeTab === "v-pills-settings" ? "show active" : ""
                  }`}
                  id="v-pills-settings"
                  role="tabpanel"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TabComponent;
