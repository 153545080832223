import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useQueryClient } from "react-query"; // Import the necessary dependencies
import Footer from "./Footer";
import { UserContext } from "./Api/context/AppContext";
import CardSummery from "./CardSummery";
import { Link, useNavigate } from "react-router-dom";
import OrderForm from "./orders/OrderForm";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import BasicModal from "./BasicModal";
import emptycart from "./assets/noproduct.Webp";
import { FaArrowLeft } from "react-icons/fa6";
import OrderSummary from "./OrderSummery";
import wikiimage from "../components/assets/logowikitek.png";

const ShoppingCart = () => {
  const { data, removeFromCard, user, showForm } = useContext(UserContext);
  console.log(data, "shopping____________________");
  const [orderCreated, setOrderCreated] = useState();
  const [open, setOpen] = React.useState(false);
  const [modalmsg, setmodalmsg] = useState();
  const [usedetails, setuserdetails] = useState([]);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    billing_company_name: "",
    billing_pincode: "",
    billing_gst_no: "",
    shipping_company_name: "",
    shipping_pincode: "",
    name: "",
    mobile: "",
    email: "",
    marketplace: "",
    user: user,
    billing_address: "", //billing.id,
    billing_country: "", // billing.country_id,
    shipping_address: "", //shipping.id,
    shipping_country: "", // shipping.country_id
  });

  const [errors, setErrors] = useState({
    billing_company_name: "",
    billing_pincode: "",
    billing_gst_no: "",
    shipping_company_name: "",
    shipping_pincode: "",
    name: "",
    mobile: "",
    email: "",
  });

  // console.log(data, "datashoopingcart.jsx")

  // const gstPercent = data.length > 0 ? data[0].part_number.gst_itm.country_gst[0].gst_percent : 0;
  // console.log("GST Percent:", gstPercent);

  const gstPercent =
    Array.isArray(data) && data.length > 0
      ? data[0]?.part_number?.gst_itm?.country_gst?.[0]?.gst_percent || 0
      : 0;

  useEffect(() => {
    const fetchUserDetails = async () => {
      const marketplace_nameId = localStorage.getItem("marketplace_nameId");
      try {
        const orgUuid = localStorage.getItem("orgid");
        const response = await axios.get(
          `https://admin.inventab.io/api/v1/org/fetch/org/address/?org=${orgUuid}`
        );
        console.log(response.data.results, "response.data form data order ");
        const productsInfo = response.data.results;
        console.log(productsInfo,"productsInfo")
        setuserdetails(productsInfo);
        const uniqueValuesSet = new Set();
        productsInfo.forEach((item) => {
          const { org, gst_no, country, pincode } = item;

          const { company_name, contact_person } = org;
          if (
            pincode &&
            pincode.pin_code &&
            country?.id &&
            gst_no &&
            company_name &&
            contact_person.first_name &&
            contact_person.email &&
            contact_person.mobile
          ) {
            const key = `${contact_person.first_name},${contact_person.email},${contact_person.mobile},${pincode.pin_code},${country?.id},${gst_no},${company_name}`;

            if (!uniqueValuesSet.has(key)) {
              uniqueValuesSet.add(key);
            }
          }
        });

        const [firstUniqueValue] = Array.from(uniqueValuesSet);
        // console.log(firstUniqueValue,"uniqueValues")
        const [
          first_name,
          email,
          mobile,
          pin_code,
          country_id,
          gst_no,
          company_name,
        ] = firstUniqueValue.split(",");

        const billinggst = gst_no;

        if (firstUniqueValue) {
          const [
            first_name,
            email,
            mobile,
            pin_code,
            country_id,
            gst_no,
            company_name,
          ] = firstUniqueValue.split(",");
          setFormData((prevFormData) => ({
            ...prevFormData,
            billing_company_name: company_name || "", // Set your value based on your logic
            billing_pincode: pin_code || "", // Set your value based on your logic
            billing_gst_no: gst_no || "", // Set your value based on your logic
            shipping_company_name: company_name || "", // Set your value based on your logic
            shipping_pincode: pin_code || "", // Set your value based on your logic
            name: first_name,
            mobile: mobile,
            email: email,
            marketplace: marketplace_nameId,
            user: user,
            billing_address: "",
            billing_country: country_id,
            shipping_address: "",
            shipping_country: country_id,
          }));
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });

    setErrors({
      ...errors,
      [field]: "",
    });
  };

  const queryClient = useQueryClient();

  // old code
  // const getPrice = (product) => {
  //   if (product.part_number.prices.length > 0) {
  //     const selectedPrice = product.part_number.prices.find(
  //       (price) =>
  //         (product.qty || 1) >= price.min_quantity &&
  //         (product.qty || 1) <= price.max_quantity
  //     );

  //     return selectedPrice ? selectedPrice.price : 0;
  //   } else {
  //     return product.part_number.mrp || 0;
  //   }
  // };

  const getPrice = (product) => {
    if (product.part_number.prices.length > 0) {
      const selectedPrice = product.part_number.prices.find(
        (price) =>
          (product.qty || 1) >= price.min_quantity &&
          (product.qty || 1) <= price.max_quantity
      );

      const basePrice = selectedPrice ? selectedPrice.price : 0;
      const gstAmount = basePrice * (gstPercent / 100);

      return basePrice + gstAmount;
    } else {
      const basePrice = product.part_number.mrp || 0;
      const gstAmount = basePrice * (gstPercent / 100);
      return basePrice + gstAmount;
    }
  };

  const totalCartPrice = data
    ? data.reduce(
        (total, product) => total + getPrice(product) * (product.qty || 1),
        0
      )
    : 0;

  const totalGSTAmount = (totalCartPrice * gstPercent) / (100 + gstPercent);

  function findBasePrice(totalPrice, gstRatePercentage) {
    // Convert the percentage rate to a decimal
    console.log("total price, gst rate", totalPrice, gstRatePercentage);
    const gstRate = gstRatePercentage / 100;
    console.log("gst rate", totalPrice / (1 + gstRate));

    return totalPrice - totalPrice / (1 + gstRate);
  }

  console.log(totalCartPrice, "totalcatrprice");

  // const handleQuantity = async (action, productId, product) => {
  //   try {
  //     const response = await axios.put(
  //       `http://143.244.142.0/api/v1/marketplace/update/cart/${productId}/`,
  //       {
  //         qty:
  //           action === "inc"
  //             ? (product.qty || 1) + 1
  //             : Math.max((product.qty ) - 1, 1),
  //       }
  //     );

  //     console.log("Item quantity updated:", response.data);
  //     queryClient.invalidateQueries(["userList-data"]);
  //   } catch (error) {
  //     console.error("Error updating item quantity:", error);
  //   }
  // };

  const handleQuantity = async (action, productId, product) => {
    try {
      if (action === "dec" && product.qty === 1) {
        removeFromCard(productId);
      } else {
        const response = await axios.put(
          `https://admin.inventab.io/api/v1/marketplace/update/cart/${productId}/`,
          {
            qty:
              action === "inc"
                ? (product.qty || 1) + 1
                : Math.max((product.qty || 1) - 1, 1),
          }
        );

        console.log("Item quantity updated:", response.data);
        queryClient.invalidateQueries(["userList-data"]);
      }
    } catch (error) {
      console.error("Error updating item quantity:", error);
    }
  };

  const handleSubmit = async () => {
    const marketplace_nameId = localStorage.getItem("make");
    try {
      const response = await axios.post(
        "https://admin.inventab.io/api/v1/marketplace/create-order/",
        formData
      );
      console.log("Success:", response.data);
      const orderId = response.data.id;

      setOrderCreated(orderId);
      await addOrderParts(orderId, response.data.order_id, marketplace_nameId);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        // console.log(errorData, 'errorData______errorData');
        setErrors({
          billing_gst_no: errorData.billing_gst_no
            ? errorData.billing_gst_no[0]
            : "",
          shipping_company_name: errorData.shipping_company_name
            ? errorData.shipping_company_name[0]
            : "",
          shipping_pincode: errorData.shipping_pincode
            ? errorData.shipping_pincode[0]
            : "",
          name: errorData.name ? errorData.name[0] : "",
          mobile: errorData.mobile ? errorData.mobile[0] : "",
          email: errorData.email ? errorData.email[0] : "",
        });
      }
    }
  };

  //originl code below
  // const addOrderParts = async (orderId, response_order_id, marketplace_nameId) => {
  //   const billinggst = formData.billing_gst_no;
  //   console.log(billinggst,"infunctiongst");
  //   try {
  //     const itemlist = data.map((item) => ({
  //       order: orderId,
  //       part_number: item.part_number.id,
  //       qty: item.qty,
  //       cgst: findBasePrice(Math.ceil(getPrice(item) * item.qty), item?.part_number?.gst_itm?.country_gst?.[0]?.gst_percent || 0) / 2,
  //       sgst: findBasePrice(Math.ceil(getPrice(item) * item.qty), item?.part_number?.gst_itm?.country_gst?.[0]?.gst_percent || 0) / 2,
  //       igst: billinggst.startsWith('27')? null  : totalGSTAmount,
  //       total: item?.part_number.mrp * item.qty,
  //       shipping_cost: null,
  //       // total_payable: Math.ceil(getPrice(item) * item.qty), // Round and then convert to integer use this code in total
  //       total_payable: parseFloat((getPrice(item) * item.qty).toFixed(2)),
  //       // total_payable: (getPrice(item) * item.qty).toFixed(2),//string
  //       user: item.user.id,
  //     }));

  //     const response = await axios.post(
  //       'http://143.244.142.0/api/v1/marketplace/checkout-order/',
  //       itemlist
  //     );

  //     console.log('Order parts added successfully:', response.data);

  //     const CartPrice = response.data.order_details
  //       ? response.data.order_details.reduce((total_payable, product) => {
  //         // Convert the total and product.total to numbers and add them up
  //         return total_payable + parseFloat(product.total_payable || 0);
  //       }, 0)
  //       : 0;

  //     // old code
  //     // const CartPrice = response.data.order_details
  //     // ? response.data.order_details.reduce((total, product) => {
  //     //   // Convert the total and product.total to numbers and add them up
  //     //   return total + parseFloat(product.total || 0);
  //     // }, 0)
  //     // : 0;

  //     // console.log(CartPrice,"total")

  //     const amountprice = response.data.razorpay_response.amount;
  //     const options = {
  //       key: process.env.REACT_APP_RAZORPAY_KEY,
  //       amount: amountprice,
  //       currency: response.data.razorpay_response.currency,
  //       name: marketplace_nameId,
  //       description: 'Payment for your order test',
  //       image: '../components/assets/logowikitek.png',
  //       // image:wikiimage,
  //       order_id: response.data.razorpay_response.id,
  //       handler: async function (response) {
  //         const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
  //         console.log('Payment successful:', response);
  //         await addPaymentDetails(orderId, CartPrice, "Success", razorpay_payment_id || "not found", razorpay_signature,);
  //         DeleteAll_Product(response_order_id)
  //       },
  //       prefill: {
  //         name: formData.name,
  //         email: formData.email,
  //         contact: formData.mobile,
  //       },
  //       theme: {
  //         color: '#3e9cd0',
  //       },
  //     };

  //     const razorpay = new window.Razorpay(options);
  //     razorpay.on('payment.failed', async function (response) {
  //       console.log('Payment failed:', response);
  //       const { order_id, payment_id } = response.error.metadata

  //       if (payment_id) {
  //         await addPaymentDetails(orderId, CartPrice, "Failure", payment_id || "not found", "No Found");
  //       }

  //       handleOpen()
  //       setmodalmsg(response.error.description)
  //     });

  //     razorpay.open();
  //   } catch (error) {
  //     console.error('Error adding order parts:', error);
  //     throw error;
  //   }
  // };

  //updated new code
  const addOrderParts = async (
    orderId,
    response_order_id,
    marketplace_nameId
  ) => {
    const billinggst = formData.billing_gst_no;
    console.log(billinggst, "infunctiongst");

    try {
      const itemlist = data.map((item) => ({
        order: orderId,
        part_number: item.part_number.id,
        qty: item.qty,
        cgst:
          findBasePrice(
            Math.ceil(getPrice(item) * item.qty),
            item?.part_number?.gst_itm?.country_gst?.[0]?.gst_percent || 0
          ) / 2,
        sgst:
          findBasePrice(
            Math.ceil(getPrice(item) * item.qty),
            item?.part_number?.gst_itm?.country_gst?.[0]?.gst_percent || 0
          ) / 2,
        igst: billinggst.startsWith("27") ? null : totalGSTAmount,
        total: item?.part_number.mrp * item.qty,
        shipping_cost: null,
        total_payable: parseFloat((getPrice(item) * item.qty).toFixed(2)),
        user: item.user.id,
      }));

      const response = await axios.post(
        "https://admin.inventab.io/api/v1/marketplace/checkout-order/",
        itemlist
      );

      console.log("Order parts added successfully:", response.data);

      const CartPrice = response.data.order_details
        ? response.data.order_details.reduce((total_payable, product) => {
            return total_payable + parseFloat(product.total_payable || 0);
          }, 0)
        : 0;

      const amountprice = response.data.razorpay_response.amount;
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amountprice,
        currency: response.data.razorpay_response.currency,
        name: marketplace_nameId,
        description: "Payment for your order test",
        image: "../components/assets/logowikitek.png",
        order_id: response.data.razorpay_response.id,
        handler: async function (response) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
            response;
          console.log("Payment successful:", response);
          await addPaymentDetails(
            orderId,
            CartPrice,
            "Success",
            razorpay_payment_id || "not found",
            razorpay_signature
          );
          DeleteAll_Product(response_order_id);
          setTimeout(() => {
            window.location.reload(); // Refresh the page after payment success
          }, 2000);
        },
        modal: {
          ondismiss: function () {
            console.log("Payment modal closed");
            window.location.reload(); // Refresh the page when the payment modal is closed
          },
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.mobile,
        },
        theme: {
          color: "#3e9cd0",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.on("payment.failed", async function (response) {
        console.log("Payment failed:", response);
        const { order_id, payment_id } = response.error.metadata;

        if (payment_id) {
          await addPaymentDetails(
            orderId,
            CartPrice,
            "Failure",
            payment_id || "not found",
            "Not Found"
          );
        }

        handleOpen();
        setmodalmsg(response.error.description);
      });

      razorpay.open();
    } catch (error) {
      console.error("Error adding order parts:", error);
      throw error;
    }
  };

  const addPaymentDetails = async (
    orderId,
    amountprice,
    status,
    paymentId,
    signatureId
  ) => {
    try {
      const paymentDetailsPayload = {
        order: orderId,
        amount: amountprice,
        status: status,
        payment_id: paymentId,
        signature_id: signatureId,
      };

      const response = await axios.post(
        "https://admin.inventab.io/api/v1/marketplace/add/payment-details/",
        paymentDetailsPayload
      );
      console.log("Payment details added successfully:", response.data);
    } catch (error) {
      console.error("Error adding payment details:", error);
      throw error;
    }
  };

  const DeleteAll_Product = (orderId) => {
    data.map((item) => {
      removeFromCard(item.id);
      navigate(`/success-order?orderId=${orderId}`);
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <div
          className="d-flex mt-2"
          style={{ backgroundColor: "rgba(128, 113, 113, 0.2)" }}
        >
          <div
            className="top_route justify-content-start"
            style={{ marginLeft: "70px" }}
          >
            <>
              {/* <Link to="/home" className="btn_top">
                <i className="fas fa-home text-dark mr-1"></i> Home
              </Link> */}
              <i className="fas fa-shopping-cart text-dark mr-1 "></i>
              Shopping Cart
            </>
          </div>
        </div>

        {/* Cart Start */}
        {!data?.length && (
          <div className="container" style={{ height: "auto" }}>
            <div className="emptycart">
              <div className="w-100 h-100">
                <img src={emptycart} className="w-50 h-50" />
              </div>
              <div className="mt-5 mb-5">
                <h3 className="text-warning mb-5">No Product in the cart.</h3>
                <Link to="/home" className="btn buttonbg rounded p-3">
                  Return to Shop
                </Link>
              </div>
            </div>
          </div>
        )}

        {!!data?.length && (
          // <div className="container-fluid pt-5 m-5" >
          //   {
          //     showForm ? <>
          //       <div className="row px-xl-5" style={{ marginRight: '150px', marginLeft: '100px' }}>
          //         <div className="col-lg-8 table-responsivee mb-5">
          //           <OrderForm totalCartPrice={totalCartPrice} formData={formData} handleInputChange={handleInputChange} errors={errors} usedetails={usedetails} />
          //         </div>
          //         <div className="col-lg-4 ">
          //           <OrderSummary totalCartPrice={totalCartPrice} handleSubmit={handleSubmit} data={data} />
          //         </div>
          //       </div>
          //     </>
          //       :
          //       <div className="row px-xl-5" style={{ marginRight: '150px', marginLeft: '100px' }}>
          //         <div className="col-lg-8 table-responsivee mb-5">

          //           <table className="table table-bordered mb-0">
          //             <thead className="text-dark" style={{ backgroundColor: 'rgb(232, 230, 230)' }}>
          //               <tr>
          //                 <th style={{ fontWeight: 'normal' }}>PRODUCT</th>
          //                 <th style={{ fontWeight: 'normal' }}>PRICE</th>
          //                 <th style={{ fontWeight: 'normal' }}>QUANTITY</th>
          //                 <th style={{ fontWeight: 'normal' }}>TOTAL PRICE</th>
          //               </tr>
          //             </thead>
          //             <tbody className="align-middle" style={{ fontSize: '16px' }}>
          //               {data.map((product, index) => (
          //                 <tr key={index}>
          //                   <td className="align-middle col_1">
          //                     <button
          //                       className="btn btn-sm btn"
          //                       onClick={() => removeFromCard(product.id)}
          //                     >
          //                       <div class="trash-container">
          //                         <i class="fas fa-times text-danger"></i>
          //                       </div>
          //                     </button>
          //                     {product.part_number.default && product.part_number.default.attachment && (
          //                       <img
          //                         src={product.part_number.default.attachment}
          //                         alt={product.part_number.name}
          //                         className="img-contains object-cover mr-4 ml-3"
          //                       />
          //                     )}
          //                     <Link
          //                       className="text-dark"
          //                       to={`/home/detail/${product.part_number.part_number}`}
          //                     >
          //                       {product.part_number.short_description}
          //                     </Link>
          //                   </td>
          //                   <td className="align-middle col_2 text-dark">
          //                     {new Intl.NumberFormat("en-IN", {
          //                       style: "currency",
          //                       currency: "INR",
          //                     }).format(getPrice(product))}
          //                   </td>

          //                   <td className="align-middle col_3">
          //                     <div className="input-group quantity mx-auto">
          //                       <button
          //                         className="btn"
          //                         onClick={() => {
          //                           handleQuantity("dec", product.id, product);
          //                         }}
          //                       >
          //                         <i className="fa fa-minus text-dark"></i>
          //                       </button>

          //                       <span className="bg-secondary shop_add">{product.qty ||1 }</span>

          //                       <button
          //                         className="btn "
          //                         // style={{backgroundColor: 'rgba(248, 138, 28, 0.928)'}}
          //                         onClick={() => {
          //                           handleQuantity("inc", product.id, product);
          //                         }}
          //                       >
          //                         <i className="fa fa-plus text-dark"></i>
          //                       </button>
          //                     </div>
          //                   </td>
          //                   <td className="align-middle text-dark">
          //                     {new Intl.NumberFormat("en-IN", {
          //                       style: "currency",
          //                       currency: "INR",
          //                     }).format(getPrice(product) * (product.qty))}
          //                   </td>
          //                 </tr>
          //               ))}
          //             </tbody>
          //           </table>

          //           <div className="d-flex">
          //             <div className="input-group-append m-3">

          //               <Link
          //                 to="/home" // Set the 'to' attribute to the desired path
          //                 className="btn p-2 mt-3 ml-5"
          //                 style={{ color: '#1B6392', border: '2px solid #1B6392' }}
          //               >
          //                 <FaArrowLeft /> RETURN TO SHOP
          //               </Link>
          //             </div>

          //             <div className="input-group-append m-3 ml-auto">
          //               {/* <button className="btn p-2 mt-3 ml-5" style={{color: '#1B6392', border: '2px solid #1B6392'}} > <FaArrowLeft /> Return To Shop</button> */}
          //               <Link
          //                 to="/home/categories/Electronics/Telematics/22" // Set the 'to' attribute to the desired path
          //                 className="btn p-2 mt-3 mr-5"
          //                 style={{ color: '#1B6392', border: '2px solid #1B6392' }}
          //               >
          //                 UPDATE CART
          //               </Link>
          //             </div>
          //           </div>
          //         </div>
          //         <div className="col-lg-4 ">
          //           <CardSummery totalCartPrice={totalCartPrice} handleSubmit={handleSubmit} data={data} />
          //         </div>
          //       </div>
          //   }
          // </div>

          <div className="pt-2 m-1">
            {showForm ? (
              <>
                <div
                  className="row px-xl-5"
                  style={{ marginRight: "0", marginLeft: "0" }}
                >
                  <div className="col-lg-8 col-md-12 table-responsivee ">
                    <OrderForm
                      totalCartPrice={totalCartPrice}
                      formData={formData}
                      handleInputChange={handleInputChange}
                      errors={errors}
                      usedetails={usedetails}
                    />
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <OrderSummary
                      totalCartPrice={totalCartPrice}
                      handleSubmit={handleSubmit}
                      data={data}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="row px-xl-5 "
                style={{ marginRight: "0", marginLeft: "0" }}
              >
                <div className="col-lg-9 col-md-12">
                  <table className="table mb-0 w-100 table-responsive" style={{ marginRight: "0", marginLeft: "0" }}>
                    <thead
                      className="text-dark"
                      style={{ backgroundColor: "rgb(232, 230, 230)" }}
                    >
                      <tr className="table-bordered">
                        <th style={{ fontWeight: "normal" }}>PRODUCT</th>
                        <th style={{ fontWeight: "normal" }}>PRICE</th>
                        <th style={{ fontWeight: "normal" }}>QUANTITY</th>
                        <th style={{ fontWeight: "normal" }}>TOTAL PRICE</th>
                      </tr>
                    </thead>
                    <tbody
                      className="align-middle table-bordered"
                      style={{ fontSize: "16px" }}
                    >
                      {data.map((product, index) => (
                        <tr key={index}>
                          <td className="align-middle col_1">
                            <button
                              className="btn btn-sm btn"
                              onClick={() => removeFromCard(product.id)}
                            >
                              <div className="trash-container">
                                <i className="fas fa-times text-danger"></i>
                              </div>
                            </button>
                            {product.part_number.default &&
                              product.part_number.default.attachment && (
                                <img
                                  src={product.part_number.default.attachment}
                                  alt={product.part_number.name}
                                  className="img-contains object-cover mr-4 ml-3"
                                />
                              )}
                            <Link
                              className="text-dark"
                              to={`/home/detail/${product.part_number.part_number}`}
                            >
                              {product.part_number.short_description}
                            </Link>
                          </td>
                          <td className="align-middle col_2 text-dark">
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                            }).format(getPrice(product))}
                          </td>

                          <td className="align-middle col_3">
                            <div className="input-group quantity mx-auto">
                              <button
                                className="btn"
                                onClick={() => {
                                  handleQuantity("dec", product.id, product);
                                }}
                              >
                                <i className="fa fa-minus text-dark"></i>
                              </button>

                              <span className="bg-secondary shop_add">
                                {product.qty || 1}
                              </span>

                              <button
                                className="btn"
                                onClick={() => {
                                  handleQuantity("inc", product.id, product);
                                }}
                              >
                                <i className="fa fa-plus text-dark"></i>
                              </button>
                            </div>
                          </td>
                          <td className="align-middle text-dark">
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                            }).format(getPrice(product) * product.qty)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="input-group-append m-3">
                      <Link
                        to="/home"
                        className="btn p-2 mt-3 custom-text-color"
                        style={{
                          // color: "#1B6392",
                          border: "2px solid #f15b29",
                          width: "100%",
                        }} // Added width for responsiveness
                      >
                        <FaArrowLeft /> RETURN TO SHOP
                      </Link>
                    </div>

                    <div className="input-group-append m-3">
                      <Link
                        to="/home/categories/Electronics/Telematics/22"
                        className="btn p-2 mt-3 custom-text-color"
                        style={{
                          // color: "#1B6392",
                          border: "2px solid #f15b29",
                          width: "100%",
                        }} // Added width for responsiveness
                      >
                        UPDATE CART
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 mt-3 ">
                  <CardSummery
                    totalCartPrice={totalCartPrice}
                    handleSubmit={handleSubmit}
                    data={data}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <BasicModal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          modalmsg={modalmsg}
        />
      </div>
      <Footer />
    </>
  );
};

export default ShoppingCart;
