import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import { Link, useParams, useNavigate } from "react-router-dom";
// import Carousel from 'react-grid-carousel'
import { UserContext } from "./Api/context/AppContext";
import { useFetchApi } from "./Api/uesFatchapi";
import axios from "axios";
import "../components/Singleproduct.css";
import user1 from "../components/assets/no_image.png";
import Loader from "./Loader";
import { Table } from "react-bootstrap";

const SingleProduct = () => {
  const { user } = useContext(UserContext);
  const { HandleFormShow } = useContext(UserContext);
  const [partRating, setPartRating] = useState(null);
  const { id } = useParams();
  const Navigate = useNavigate();

  const [quantity, setQuantity] = useState(0);
  const incQty = () => {
    setQuantity((prevQty) => prevQty + 1);
  };
  const decQty = () => {
    setQuantity((prevQty) => {
      if (prevQty >= 1) {
        return prevQty - 1;
      }
      return prevQty; // Quantity shouldn't go below 1
    });
  };

  const [describe, setdescribe] = useState({
    longDescri: "",
    short: "",
  });

  const { data, loading } = useFetchApi(
    `https://admin.inventab.io/api/v1/parts/fetch/parts/?part_number=${id}`
  );

  console.log(data, "___________data ");
  // const { data, loading } = useFetchApi(`http://143.244.142.0/api/v1/parts/fetch/parts/?short_description=${id}`);

  const descri = data && data.length > 0 ? data[0].short_description : null;

  const getDescribe = async () => {
    try {
      if (data && data[0] && data[0].short_description) {
        const response = await axios.get(
          `https://admin.inventab.io/api/v1/parts/fetch/parts/?short_description=${descri}`
        );
        // Handle the successful response here
        // console.log(response.data.results[0].short_description, "________--descril");
        // console.log(response.data.results[0].long_description, "________--long_description");

        setdescribe({
          longDescri: response.data.results[0].long_description,
          short: response.data.results[0].short_description,
        });
        setPartRating(response.data.results[0].parts_rating);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDescribe();
  }, [descri]);

  const imagesimgl =
    !loading &&
    data &&
    data.length > 0 &&
    data[0].default &&
    data[0].default.attachment
      ? data[0].default.attachment
      : null;

  const attachment1 =
    !loading &&
    data &&
    data.length > 0 &&
    data[0].documents[0] &&
    data[0].documents[0].attachment
      ? data[0].documents[0].attachment.attachment
      : null;

  //   const finalImage = attachment1 || imagesimgl;

  // const finalImage =
  //   data?.[0]?.documents.find(
  //     (doc) => doc.visible === true && doc.part_pic === true
  //   )?.attachment || null; // Get the attachment of the first matching document // Get all visible images where part_pic is true
  // //   const finalImage = data?.[0]?.documents?.[0]?.attachment || null; // Get the attachment URL from documents

  const finalImage = data?.[0]?.documents.find((doc) => doc.part_pic === true)?.attachment || null;

  useEffect(() => {
    console.log(data, "finalImage");
    console.log(finalImage, "finalImage");
  }, [finalImage]);

  const defaultImage = user1;
  // console.log(attachment1, "_____-fiattachment1")

  const renderProductImage = () => {
    if (finalImage) {
      return <img className="w-100 h-100" src={finalImage} alt="image" />;
    } else {
      return (
        <img className="w-100 h-100" src={defaultImage} alt="default image" />
      );
    }
  };

  const { addToCard, checkId } = useContext(UserContext);

  if (loading) {
    // Display a loading message
    return (
      <div className="container">
        <div>
          <Loader></Loader>
        </div>
      </div>
    );
  }

  if (!data || data.length === 0) {
    // Data not found message
    return (
      <div className="container">
        <div>Data not found</div>
      </div>
    );
  }

  const HandleNavigate = (product, qty) => {
    addToCard(product, qty);
    HandleFormShow();
    Navigate("/home/cart");
  };

  // const renderStars = () => {
  //     const stars = [];
  //     for (let i = 1; i <= 5; i++) {
  //         stars.push(<i key={i} className="fas fa-star text-warning"></i>);
  //     }
  //     return stars;
  // };
  console.log(partRating, "parts ratingusestate");

  // const sortedReviews = [...partRating].sort((a, b) => new Date(b.created) - new Date(a.created));
  const sortedReviews = Array.isArray(partRating)
    ? [...partRating].sort((a, b) => new Date(b.created) - new Date(a.created))
    : [];

  const sortlenght = sortedReviews.length;

  let totalRating = 0;
  let totalReviews = sortedReviews.length;

  for (const review of sortedReviews) {
    totalRating += parseFloat(review.rating);
  }

  // Calculate average rating
  const averageRating =
    totalReviews > 0 ? (totalRating / totalReviews).toFixed(2) : 0;
  console.log(averageRating, "average ratingusestate");

  const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={`fas fa-star ${
            i <= rating ? "text-warning" : "text-muted"
          }`}
        ></i>
      );
    }
    return stars;
  };

  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(averageRating);
    const halfStar = averageRating - fullStars >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<i key={`full-${i}`} className="fa fa-star text-warning"></i>);
    }

    if (halfStar) {
      stars.push(
        <i key="half" className="fa fa-star-half-alt text-warning"></i>
      );
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <i key={`empty-${i}`} className="fa fa-star text-secondary"></i>
      );
    }

    return stars;
  };

  return (
    <>
      <div
        className="d-flex mt-2"
        style={{ backgroundColor: "rgba(128, 113, 113, 0.2)" }}
      >
        <div
          className="top_route justify-content-start"
          style={{ marginLeft: "70px" }}
        >
          <>
            <Link to="/" className="btn_top">
              <i className="fas fa-home text-dark mr-1"></i> Home /
            </Link>
            Product Catalog
          </>
        </div>
      </div>
      {/* <!-- Shop Detail Start --> */}
      <div className="container-fluid py-5 ">
        <div className="row px-xl-5">
          <div className="col-lg-5 pb-5">
            <div id="product-carousel" className="carousel slide">
              <div className="d-flex justify-content-center align-items-center border carousel-container">
                {/* <img className="w-100 h-100" src={finalImage} alt="image" /> */}
                <img
                  className="w-50 h-60"
                  src={finalImage ? finalImage : defaultImage} // Updated to use conditional rendering
                  alt="Product"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-7 pb-5">
            <h6 className="mb-1">{data[0]?.part_number}</h6>
            <h3 className="font-weight-semi-bold">
              {data[0].short_description}
            </h3>

            <h3 className="font-weight-semi-bold">
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(data[0].mrp)}
            </h3>
            {/* <p className="mb-1"> {data[0].short_description}</p> */}
            <p className="mb-1"> {data[0].uom?.name}</p>
            <p className="mb-1">{data[0]?.part_category?.name}</p>
            <p className="mb-1">{data[0]?.sub_category?.name}</p>
            <div className="mt-3 mb-3">
              {renderStars()} <strong>Reviewed by {sortlenght} people </strong>
            </div>

            <div className="d-flex align-items-center mb-4 pt-2">
              {user ? (
                <div
                  className="input-group  quantity mr-3"
                  style={{ width: "130px;" }}
                >
                  <div className="input-group-btn">
                    <button
                      className="btn btn-primary btn-minus footerbg rounded me zoom-on-hover text-light"
                      onClick={decQty}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    className=" bg-secondary text-center add_bor"
                    value={quantity}
                  />
                  <div className="input-group-btn">
                    <button
                      className="btn btn-primary btn-plus rounded zoom-on-hover text-light"
                      style={{ backgroundColor: "rgba(248, 138, 28, 0.928)" }}
                      onClick={incQty}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                  {quantity > 0 ? (
                    <>
                      {!checkId.includes(data[0].id) ? (
                        <button
                          className="btn btn-sm text-white footerbg p-2 px-2 rounded ml-2"
                          onClick={() => addToCard(data[0], quantity)}
                        >
                          <i className="fa fa-shopping-cart mr-1"></i> Add To
                          Cart
                        </button>
                      ) : (
                        <Link
                          to="/home/cart"
                          className="btn btn-sm text-white p-2 px-2 rounded ml-2"
                          style={{
                            backgroundColor: "rgba(248, 138, 28, 0.928)",
                            border: "none",
                          }}
                        >
                          <i className="fa fa-shopping-cart mr-1"></i> Go To
                          Cart
                        </Link>
                      )}
                      {!checkId.includes(data[0].id) ? (
                        <button
                          className="btn btn-sm p-2 px-2 rounded ml-2 text-light btn-bounce-on-hover"
                          style={{
                            backgroundColor: "rgba(248, 138, 28, 0.928)",
                          }}
                          onClick={() => HandleNavigate(data[0], quantity)}
                        >
                          <i className="fas fa-bolt"></i> Buy Now
                        </button>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : (
                <Link></Link>
              )}
            </div>
          </div>
        </div>
        <div className="row px-xl-5">
          <div className="col">
            <div className="nav nav-tabs justify-content-center border-secondary mb-4">
              <a
                className="nav-item nav-link active"
                data-toggle="tab"
                href="#tab-pane-1"
              >
                Description
              </a>
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#tab-pane-2"
              >
                Information
              </a>
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#tab-pane-3"
              >
                Reviews
              </a>
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#tab-pane-4"
              >
                Related Docs
              </a>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab-pane-1">
                <h4 className="mb-3">Product Description</h4>
                <p>{describe.short}</p>
                <p>{describe.longDescri}</p>
              </div>

              <div className="tab-pane fade" id="tab-pane-2">
                <h4 className="mb-3">Additional Information</h4>
                <div className="info">
                  <div className="row">
                    <div className="col-6">
                      <ul>
                        <li>Part-Number</li>
                        <li>{data[0].part_number}</li>
                      </ul>
                      <ul>
                        <li>height</li>
                        <li>{data[0].height}</li>
                      </ul>
                      <ul>
                        <li>length</li>
                        <li>{data[0].length}</li>
                      </ul>
                      <ul>
                        <li>weight</li>
                        <li>{data[0].weight}</li>
                      </ul>
                      <ul>
                        <li>warranty-period</li>
                        <li>{data[0].warranty_period}</li>
                      </ul>
                      <ul>
                        <li>warranty-terms</li>
                        <li>{data[0].warranty_terms}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="reviews tab-pane fade " id="tab-pane-3">
                <h1>Customer Reviews</h1>
                <div className="reviews-container ">
                  {sortedReviews && sortedReviews.length > 0 ? (
                    sortedReviews.map((review) => (
                      <div key={review.id} className=" mb-3 p-2 border">
                        <div className="card-body">
                          <div className="mx-5">
                            <p className="card-title">
                              Name: {review?.rating_user?.first_name}{" "}
                              {review?.rating_user?.last_name}
                            </p>
                            <p className="card-text">
                              Rating: {renderRatingStars(review?.rating)}
                            </p>
                            <p className="card-text">
                              Comment: {review?.comment}
                            </p>
                            <p className="card-text">
                              Review date:{" "}
                              {new Date(review?.created).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No reviews available for this product</p>
                  )}
                </div>
              </div>

              <div className="tab-pane fade" id="tab-pane-4">
                <Table className="table-bordered w-50">
                  <thead>
                    <tr>
                      <th>Document</th>
                      <th>Attachment</th>
                    </tr>
                  </thead>
                  {/* <tbody>
                    {data[0].documents.filter(
                      (document) =>
                        document.visible === true && document.part_pic === false
                    ).length > 0 ? ( // Filter documents based on conditions
                      data[0].documents
                        .filter(
                          (document) =>
                            document.visible === true &&
                            document.part_pic === false
                        )
                        .map((document) => (
                          <tr key={document.id}>
                            <td>{document?.name}</td>
                            <td>
                              {" "}
                              <img
                                src={document?.attachment}
                                height={50}
                                width={50}
                              />
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="2">Document is not available</td>
                      </tr>
                    )}
                  </tbody> */}

                  <tbody>
                    {data[0].documents.filter(
                      (document) =>
                        document.visible === true && document.part_pic === false
                    ).length > 0 ? ( // Filter documents based on conditions
                      data[0].documents
                        .filter(
                          (document) =>
                            document.visible === true &&
                            document.part_pic === false
                        )
                        .map((document) => (
                          <tr key={document.id}>
                            <td>
                              <strong>{document?.name}</strong>
                              <p>
                                {/* <strong>Description:</strong> */}
                                {document?.description}
                              </p>
                            </td>

                            <td>
                              <a
                                href={document?.attachment}
                                download 
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary"
                              >
                                <i className="fas fa-download"></i> Download
                              </a>
                            </td>
                            {/* <td>
                              {document?.attachment.endsWith(".pdf") ? ( // Check if attachment is a PDF
                                <a
                                  href={document?.attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fas fa-file-pdf"
                                    style={{ fontSize: "24px", color: "red" }}
                                  ></i>{" "}
                                  View PDF
                                </a>
                              ) : document?.attachment.endsWith(".zip") ? ( // Check if attachment is a ZIP
                                <a
                                  href={document?.attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fas fa-file-archive"
                                    style={{ fontSize: "24px", color: "blue" }}
                                  ></i>{" "}
                                  Download ZIP
                                </a>
                              ) : document?.attachment.endsWith(".exe") ? ( // Check if attachment is an EXE
                                <i
                                  className="fas fa-file-exe"
                                  style={{ fontSize: "24px", color: "orange" }}
                                ></i>
                              ) : document?.attachment.endsWith(".apk") ? ( // Check if attachment is an APK
                                <i
                                  className="fas fa-android"
                                  style={{ fontSize: "24px", color: "green" }}
                                ></i>
                              ) : (
                                <a
                                  href={document?.attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={document?.attachment}
                                    height={20}
                                    width={20}
                                    alt="Document"
                                    style={{ cursor: "pointer" }} // Add pointer cursor for image
                                  />
                                  <span> View Image</span>
                                </a>
                              )}
                            </td> */}
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="2">Document is not available</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleProduct;
