// import React, { useState } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import axios from 'axios';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { AiOutlineAppstore, AiOutlineMenu, AiFillDelete } from 'react-icons/ai';
// import { RxCross2 } from "react-icons/rx";

// import CartItem from './product/CartItem';
// import Footer from './Footer';
// import Loader from './Loader';
// import { useQuery } from 'react-query';
// import Categorieslist from './Categorieslist';

// const Testshop = () => {
//     const { partCategoryName, subCategoryName, subCategoryId } = useParams();

//     const decodedPartCategoryName = decodeURI(partCategoryName);
//     const decodedSubCategoryName = decodeURI(subCategoryName);

//     const [priceFilter, setPriceFilter] = useState('all');
//     const [selectedCategories, setSelectedCategories] = useState([]);
//     const [isGridView, setIsGridView] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredProducts, setFilteredProducts] = useState([]);

//     const priceFilterMapping = {
//         'all': 'All Prices',
//         'price-1': '₹0 - ₹1000',
//         'price-2': '₹1000 - ₹2000',
//         'price-3': '₹2000 - ₹3000',
//         'price-4': '₹3000 - ₹4000',
//         'price-5': '₹4000+'
//     };

//     const fetchData = async () => {
//         console.log('in fetch data')
//         try {

//             const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?sub_category=${subCategoryId}`);
//             // const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?market_place=enata-automotive`);

//             console.log(response.data.results, "api response")
//             return response.data.results;
//         } catch (error) {
//             throw error;
//         }
//     };

//     const { data, isLoading, isError, error } = useQuery(['categories-data', subCategoryId], fetchData);

//     const subcat = [...new Set((data?.map((product) => product.sub_category.name) || []))];

//     const handlePriceFilterChange = (priceRange) => {
//         setPriceFilter(priceRange);
//     };

//     const handleCategoryFilterChange = (category) => {
//         if (selectedCategories.includes(category)) {
//             setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
//         } else {
//             setSelectedCategories([...selectedCategories, category]);
//         }
//     };

//     const filterProductsByPriceAndCategory = (products, priceFilter, categoryFilter) => {
//         return (products || []).filter((product) => {
//             const price = product.mrp;
//             const category = product.sub_category.name;
//             const marketplace = (product.market_place[0]?.marketplace_name || '').toLowerCase(); // Extract the marketplace_name, if available

//             if (
//                 priceFilter !== 'all' &&
//                 categoryFilter.length > 0 &&
//                 marketplace === 'enata-automotive' // Check for marketplace_name 'enata-automotive'
//             ) {
//                 return (
//                     priceFilterCheck(price, priceFilter) &&
//                     categoryFilter.includes(category) &&
//                     marketplace === 'enata-automotive'
//                 );
//             } else if (priceFilter !== 'all') {
//                 return priceFilterCheck(price, priceFilter) && marketplace === 'enata-automotive';
//             } else if (categoryFilter.length > 0) {
//                 return categoryFilter.includes(category) && marketplace === 'enata-automotive';
//             } else {
//                 return marketplace === 'enata-automotive';
//             }
//         });
//     };

//     const priceFilterCheck = (price, priceFilter) => {
//         switch (priceFilter) {
//             case 'price-1':
//                 return price >= 0 && price <= 1000;
//             case 'price-2':
//                 return price > 1000 && price <= 2000;
//             case 'price-3':
//                 return price > 2000 && price <= 3000;
//             case 'price-4':
//                 return price > 3000 && price <= 4000;
//             case 'price-5':
//                 return price > 4000;
//             default:
//                 return false;
//         }
//     };

//     const handleDeleteSelectedCategory = (category) => {
//         setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
//     };

//     const showGridView = () => {
//         setIsGridView(true);
//     };

//     const showListView = () => {
//         setIsGridView(false);
//     };

//     const handleInputChange = (event) => {
//         const searchTerm = event.target.value;
//         setSearchTerm(searchTerm);

//         const filtered = filterProductsBySearch(data, searchTerm);

//         setFilteredProducts(filtered);
//     };

//     const filterProductsBySearch = (products, searchTerm) => {
//         return (products || []).filter((product) => {
//             const shortDescription = (product.short_description || '').toLowerCase();
//             return shortDescription.includes(searchTerm.toLowerCase());
//         });
//     };

//     // const filteredProducts = filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);
//     const productsToDisplay = searchTerm ? filteredProducts : filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);

//     return (
//         <>
//             {/* <div className="container-fluid  mb-2">
//                 <div className=" text-center ">
//                     <h2 className="font-weight-semi-bold text-dark text-uppercase mb-3">Product Catalog</h2>
//                     <hr className='w-25'></hr>
//                 </div>
//             </div> */}
//             <div className="d-flex mt-2" style={{ backgroundColor: 'rgba(128, 113, 113, 0.2)' }}>
//                 <div className="top_route justify-content-start" style={{ marginLeft: '70px' }}>
//                     {partCategoryName && subCategoryName && (
//                         <>
//                             <Link to="/home" className="btn_top">
//                             <i className="fas fa-home text-dark mr-1"></i> Home / {decodedPartCategoryName} /
//                             </Link>
//                             {decodedSubCategoryName}
//                         </>
//                     )}
//                 </div>
//             </div>

//             <div className="container-fluid  shop_prod">
//                 <div className="row px-xl-5">
//                     <div className="col-lg-3 col-md-12">
//                         {data?.length > 0 && (
//                             <div className="border-bottom mb-4 pb-4">
//                                 <Categorieslist />
//                                 <h5 className="font-weight-semi-bold mb-4">Filter by Price</h5>
//                                 <form>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'all'}
//                                             onChange={() => handlePriceFilterChange('all')}
//                                             id="price-all"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-all">
//                                             All Prices
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-1'}
//                                             onChange={() => handlePriceFilterChange('price-1')}
//                                             id="price-1"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-1">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(0)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(1000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-2'}
//                                             onChange={() => handlePriceFilterChange('price-2')}
//                                             id="price-2"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-2">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(1000)}- {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(2000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-3'}
//                                             onChange={() => handlePriceFilterChange('price-3')}
//                                             id="price-3"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-3">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(2000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(3000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-4'}
//                                             onChange={() => handlePriceFilterChange('price-4')}
//                                             id="price-4"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-4">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(3000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(4000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-5'}
//                                             onChange={() => handlePriceFilterChange('price-5')}
//                                             id="price-5"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-5">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(4000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(5000)}
//                                         </label>
//                                     </div>
//                                 </form>
//                             </div>
//                         )}

//                         {data?.length > 0 && (
//                             <div className="border-bottom mb-4 pb-4 d-none">
//                                 <h5 className="font-weight-semi-bold mb-4">Filter by Categories</h5>
//                                 <form>
//                                     {subcat.map((category) => (
//                                         <div
//                                             key={category}
//                                             className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"
//                                         >
//                                             <input
//                                                 type="checkbox"
//                                                 className="custom-control-input"
//                                                 checked={selectedCategories.includes(category)}
//                                                 onChange={() => handleCategoryFilterChange(category)}
//                                                 id={`category-${category}`}
//                                             />
//                                             <label
//                                                 className="custom-control-label"
//                                                 htmlFor={`category-${category}`}
//                                             >
//                                                 {category}
//                                             </label>
//                                         </div>
//                                     ))}
//                                 </form>
//                             </div>
//                         )}
//                     </div>

//                     <div className="col-lg-9 col-md-12">
//                         <div className=" d-flex flex-wrap">
//                             {selectedCategories.length > 0 && (
//                                 selectedCategories.map((item, index) => (
//                                     <div key={index} className="d-flex align-items-center mb-2">
//                                         <button
//                                             className="btn btn-primary mr-2"
//                                             onClick={() => handleDeleteSelectedCategory(item)}
//                                         >
//                                             {item} <AiFillDelete />
//                                         </button>
//                                     </div>
//                                 ))
//                             )}
//                         </div>

//                         <div className="row pb-3" style={{marginRight: '150px'}}>
//                             <div className="col-12 pb-1">
//                                 <div className="d-flex align-items-center justify-content-start mb-2">
//                                     <form action="">
//                                         <div className="input-group">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="Search by name"
//                                                 value={searchTerm}
//                                                 onInput={handleInputChange}
//                                             />

//                                             <div className="input-group-append ml-3">
//                                                 <span className="input-group-text">
//                                                     <i className="fa fa-search text-dark flip-on-hover"></i>
//                                                 </span>
//                                                 <span
//                                                     className="input-group-text text-dark ml-3"
//                                                     onClick={showGridView}
//                                                 >
//                                                     <AiOutlineAppstore />
//                                                 </span>
//                                                 <span
//                                                     className="input-group-text text-dark ml-3"
//                                                     onClick={showListView}
//                                                 >
//                                                     <AiOutlineMenu />
//                                                 </span>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>

//                             <div className="col-11 d-flex ml-3 mb-4" style={{ backgroundColor: 'rgba(128, 113, 113, 0.2)' }}>
//                             {partCategoryName && subCategoryName && (
//                                 <div className='pt-2 pb-2'>
//                                 Active Filters:
//                                 <span className='text-dark ml-3'>{decodedPartCategoryName} <RxCross2 /></span>
//                                 <span className='text-dark ml-3'>{decodedSubCategoryName} <RxCross2 /></span>
//                                 <span className='text-dark ml-3'>{priceFilterMapping[priceFilter]}<RxCross2 /></span>
//                                 </div>
//                             )}
//                             </div>

//                             {isLoading ? (
//                                 <Loader />
//                             ) : (productsToDisplay || []).length > 0 ? (
//                                 <InfiniteScroll
//                                     dataLength={(productsToDisplay || []).length}
//                                 >
//                                     <div className={`d-flex ${isGridView ? 'flex-wrap' : 'flex-column'}`}>
//                                         {(productsToDisplay || []).map((product, index) => (
//                                             <CartItem product={product} key={index} isGridView={isGridView} />
//                                         ))}
//                                     </div>
//                                 </InfiniteScroll>
//                             ) : (
//                                 <div>
//                                     no products to show
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     );
// };
// export default Testshop;

import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { AiOutlineAppstore, AiOutlineMenu, AiFillDelete } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import CartItem from "./product/CartItem";
import Footer from "./Footer";
import Loader from "./Loader";
import { useQuery } from "react-query";
import Categorieslist from "./Categorieslist";

const Testshop = () => {
  const { partCategoryName, subCategoryName, subCategoryId } = useParams();

  const decodedPartCategoryName = decodeURI(partCategoryName);
  const decodedSubCategoryName = decodeURI(subCategoryName);

  const [priceFilter, setPriceFilter] = useState("all");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isGridView, setIsGridView] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const priceFilterMapping = {
    all: "All Prices",
    "price-1": "₹0 - ₹1000",
    "price-2": "₹1000 - ₹2000",
    "price-3": "₹2000 - ₹3000",
    "price-4": "₹3000 - ₹4000",
    "price-5": "₹4000+",
  };

  const fetchData = async () => {
    console.log("in fetch data");
    try {
      const response = await axios.get(
        `https://admin.inventab.io/api/v1/parts/fetch/parts/?sub_category=${subCategoryId}`
      );
      // const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?market_place=enata-automotive`);

      console.log(response.data.results, "api response :>>>");
      return response.data.results;
    } catch (error) {
      throw error;
    }
  };

  const { data, isLoading, isError, error } = useQuery(
    ["categories-data", subCategoryId],
    fetchData
  );
  console.log(data, "data:>>>");
  const subcat = [
    ...new Set(data?.map((product) => product.sub_category.name) || []),
  ];

  const handlePriceFilterChange = (priceRange) => {
    setPriceFilter(priceRange);
  };

  const handleCategoryFilterChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const filterProductsByPriceAndCategory = (
    products,
    priceFilter,
    categoryFilter
  ) => {
    return (products || []).filter((product) => {
      const price = product.mrp;
      const category = product.sub_category.name;
      const marketplace = (
        product.market_place[0]?.marketplace_name || ""
      ).toLowerCase(); // Extract the marketplace_name, if available

      if (
        priceFilter !== "all" &&
        categoryFilter.length > 0 &&
        marketplace === "enata-automotive" // Check for marketplace_name 'enata-automotive'
      ) {
        return (
          priceFilterCheck(price, priceFilter) &&
          categoryFilter.includes(category) &&
          marketplace === "enata-automotive"
        );
      } else if (priceFilter !== "all") {
        return (
          priceFilterCheck(price, priceFilter) &&
          marketplace === "enata-automotive"
        );
      } else if (categoryFilter.length > 0) {
        return (
          categoryFilter.includes(category) &&
          marketplace === "enata-automotive"
        );
      } else {
        return marketplace === "enata-automotive";
      }
    });
  };

  const priceFilterCheck = (price, priceFilter) => {
    switch (priceFilter) {
      case "price-1":
        return price >= 0 && price <= 1000;
      case "price-2":
        return price > 1000 && price <= 2000;
      case "price-3":
        return price > 2000 && price <= 3000;
      case "price-4":
        return price > 3000 && price <= 4000;
      case "price-5":
        return price > 4000;
      default:
        return false;
    }
  };

  const handleDeleteSelectedCategory = (category) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
  };

  const handleRemovePartCategory = () => {
    // Logic to remove part category filter
  };

  const handleRemoveSubCategory = () => {
    // Logic to remove sub category filter
  };

  const handleRemovePriceFilter = () => {
    setPriceFilter("all");
  };

  const showGridView = () => {
    setIsGridView(true);
  };

  const showListView = () => {
    setIsGridView(false);
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    const filtered = filterProductsBySearch(data, searchTerm);

    setFilteredProducts(filtered);
  };

  const filterProductsBySearch = (products, searchTerm) => {
    return (products || []).filter((product) => {
      const shortDescription = (product.short_description || "").toLowerCase();
      return shortDescription.includes(searchTerm.toLowerCase());
    });
  };

  const productsToDisplay = searchTerm
    ? filteredProducts
    : filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);

  console.log(productsToDisplay, "productsToDisplay");
  // const finalProducts = productsToDisplay.filter(
  //   (product) => product?.documents[0]?.visible === true
  // );
  // console.log(finalProducts, "finalProducts");
  if (isLoading) return <Loader />;
  return (
    <>
      {/* <div
        className="d-flex "
        style={{ backgroundColor: "rgba(128, 113, 113, 0.2)", margin: "0" }}
      >
        <div className="mt-4" style={{ marginLeft: "20px" }}>
          {partCategoryName && subCategoryName && (
            <>
              <Link to="/home" className="btn_top">
                <i className="fas fa-home text-dark mr-1"></i> Home /{" "}
                {decodedPartCategoryName} / {decodedSubCategoryName}
              </Link>
            </>
          )}
        </div>

        <form
          action=""
          className="d-flex justify-content-start mt-3"
          style={{ marginLeft: "auto" }}
        >
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchTerm}
              onInput={handleInputChange}
            />

            <div className="input-group-append ml-3">
              <span className="input-group-text">
                <i className="fa fa-search text-dark flip-on-hover"></i>
              </span>

              <div className="input-group-append ml-3">
                <span
                  className="input-group-text"
                  onClick={isGridView ? showListView : showGridView}
                >
                  {isGridView ? <AiOutlineMenu /> : <AiOutlineAppstore />}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div> */}

      <div
        className="d-flex flex-column flex-md-row"
        style={{ backgroundColor: "rgba(128, 113, 113, 0.2)", margin: "0" }}
      >
        <div className="mt-4" style={{ marginLeft: "20px" }}>
          {partCategoryName && subCategoryName && (
            <Link to="/" className="btn_top">
              <i className="fas fa-home text-dark mr-1"></i> Home /{" "}
              {decodedPartCategoryName} / {decodedSubCategoryName}
            </Link>
          )}
        </div>

        <form action="" className="d-flex justify-content-start mt-3 ml-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchTerm}
              onInput={handleInputChange}
            />
            <div className="input-group-append ml-3">
              <span className="input-group-text">
                <i className="fa fa-search text-dark flip-on-hover"></i>
              </span>
              <span
                className="input-group-text"
                onClick={isGridView ? showListView : showGridView}
              >
                {isGridView ? <AiOutlineMenu /> : <AiOutlineAppstore />}
              </span>
            </div>
          </div>
        </form>
      </div>

      <div className="shop_prod" style={{ marginTop: "10px" }}>
        <div className="row px-xl-5">
          <div
            className="col-lg-3 col-md-12 "
            style={{ maxWidth: "300px", fontSize: "small" }}
          >
            {data?.length > 0 && (
              <div
                className="border-bottom mb-4 pb-4"
                style={{ fontSize: "small" }}
              >
                <Categorieslist view={true} />
                <h5
                  className="font-weight-semi-bold mb-4 "
                  style={{ marginLeft: "12px" }}
                >
                  Filter by Price
                </h5>
                <div className="container">
                  <form>
                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={priceFilter === "all"}
                        onChange={() => handlePriceFilterChange("all")}
                        id="price-all"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="price-all"
                      >
                        All Prices
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={priceFilter === "price-1"}
                        onChange={() => handlePriceFilterChange("price-1")}
                        id="price-1"
                      />
                      <label className="custom-control-label" htmlFor="price-1">
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(0)}{" "}
                        -{" "}
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(1000)}
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={priceFilter === "price-2"}
                        onChange={() => handlePriceFilterChange("price-2")}
                        id="price-2"
                      />
                      <label className="custom-control-label" htmlFor="price-2">
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(1000)}{" "}
                        -{" "}
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(2000)}
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={priceFilter === "price-3"}
                        onChange={() => handlePriceFilterChange("price-3")}
                        id="price-3"
                      />
                      <label className="custom-control-label" htmlFor="price-3">
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(2000)}{" "}
                        -{" "}
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(3000)}
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={priceFilter === "price-4"}
                        onChange={() => handlePriceFilterChange("price-4")}
                        id="price-4"
                      />
                      <label className="custom-control-label" htmlFor="price-4">
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(3000)}{" "}
                        -{" "}
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(4000)}
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between">
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={priceFilter === "price-5"}
                        onChange={() => handlePriceFilterChange("price-5")}
                        id="price-5"
                      />
                      <label className="custom-control-label" htmlFor="price-5">
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(4000)}{" "}
                        -{" "}
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(5000)}
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {data?.length > 0 && (
              <div className="border-bottom mb-4 pb-4 d-none">
                <h5 className="font-weight-semi-bold mb-4">
                  Filter by Categories
                </h5>
                <form>
                  {subcat.map((category) => (
                    <div
                      key={category}
                      className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={selectedCategories.includes(category)}
                        onChange={() => handleCategoryFilterChange(category)}
                        id={`category-${category}`}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`category-${category}`}
                      >
                        {category}
                      </label>
                    </div>
                  ))}
                </form>
              </div>
            )}
          </div>

          <div className="col-lg-9 col-md-12">
            <div className=" d-flex flex-wrap">
              {selectedCategories.length > 0 &&
                selectedCategories.map((item, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={() => handleDeleteSelectedCategory(item)}
                    >
                      {item} <AiFillDelete />
                    </button>
                  </div>
                ))}
            </div>

            <div className="row pb-3" style={{}}>
              <div className="col-12 pb-1">
                <div className="d-flex align-items-center justify-content-start mb-2">
                  <div
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {" "}
                    {/* Aligns this div to the right */}
                    {partCategoryName && subCategoryName && (
                      <div className="pt-2 pb-2">
                        Active Filters:
                        <span
                          className="text-dark ml-3"
                          onClick={handleRemovePartCategory}
                        >
                          {decodedPartCategoryName} <RxCross2 />
                        </span>
                        <span
                          className="text-dark ml-3"
                          onClick={handleRemoveSubCategory}
                        >
                          {decodedSubCategoryName} <RxCross2 />
                        </span>
                        <span
                          className="text-dark ml-3"
                          onClick={handleRemovePriceFilter}
                        >
                          {priceFilterMapping[priceFilter]}
                          <RxCross2 />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className=" justify-content-center">
                {(productsToDisplay || []).length > 0 ? (
                  <InfiniteScroll dataLength={(productsToDisplay || []).length} 
                  >
                    <div
                      className={`d-flex ${
                        isGridView ? "flex-wrap" : "flex-column"
                      } `}
                    >
                      {(productsToDisplay || []).map((product, index) => (
                        <CartItem
                          product={product}
                          key={index}
                          isGridView={isGridView}
                          product_length={productsToDisplay.length}
                        />
                      ))}
                    </div>
                  </InfiniteScroll>
                ) : (
                  <div>no products to show</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Testshop;
