// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import { useReactToPrint } from 'react-to-print';

// const OrderSingledata = ({ OrderSigledata, Login }) => {
//     const { org, user_id } = JSON.parse(localStorage.getItem('LoginResponse'));
//     const orgid = localStorage.getItem('orgid');
//     const [orgInfo, setOrgInfo] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [selectedRating, setSelectedRating] = useState(0);
//     const [comment, setComment] = useState('');
//     const [currentPart, setCurrentPart] = useState(null);
//     const componentRef = useRef();

//     useEffect(() => {
//         const orgDetailsUrl = `http://143.244.142.0/api/v1/org/get/all/org/details/?id=${orgid}`;
//         axios.get(orgDetailsUrl)
//             .then(response => {
//                 setOrgInfo(response.data.results[0]);
//             })
//             .catch(error => {
//                 console.log(error);
//             });
//     }, [orgid]);

//     const handlePrint = useReactToPrint({
//         content: () => componentRef.current,
//     });

//     const handleStarClick = (part) => {
//         setCurrentPart(part);
//         setShowModal(true);
//     };

//     const handleRatingChange = (rating) => {
//         setSelectedRating(rating);
//     };

//     const handleCommentChange = (e) => {
//         setComment(e.target.value);
//     };

//     const handleSubmitReview = () => {
//         const payload = {
//             parts: currentPart.id,
//             rating: selectedRating,
//             comment: comment,
//             rating_user: user_id
//         };

//         axios.post(`http://143.244.142.0/api/v1/parts/create/part-rating/`, payload)
//             .then(response => {
//                 console.log('Review submitted successfully:', response.data);
//                 setShowModal(false);
//                 setSelectedRating(0);
//                 setComment('');
//             })
//             .catch(error => {
//                 console.error('Error submitting review:', error);
//             });
//     };

//     const renderStars = (part) => {
//         const stars = [];
//         for (let i = 1; i <= 5; i++) {
//             stars.push(
//                 <i
//                     key={i}
//                     className="fas fa-star text-warning"
//                     onClick={() => handleStarClick(part)}>
//                     </i>
//             );
//         }
//         return stars;
//     };

//     if (!OrderSigledata || !orgInfo) {
//         return null;
//     }

//     console.log(OrderSigledata, "Order Sigle Data");

//     return (
//         <>
//             <div style={{ marginLeft: '200px', marginRight: '200px' }}>
//                 <div className="pb-3 pr-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
//                     <button style={{ border: '1px solid rgba(128, 113, 113, 0.2)' }} onClick={handlePrint}>Print</button>
//                 </div>
//                 <div ref={componentRef}>
//                     <div className="d-flex justify-content-center pt-2 pb-2" style={{ backgroundColor: 'rgba(128, 113, 113, 0.2)' }}>
//                         <div className="text-dark">
//                             <>Order Details</>
//                         </div>
//                     </div>
//                     <div className="d-flex justify-content-between align-items-center mb-2">
//                         <div className="d-flex flex-column text-left">
//                             {orgInfo.logo && (
//                                 <img
//                                     src={orgInfo.logo}
//                                     alt="logo"
//                                     style={{ width: "200px" }}
//                                 />
//                             )}
//                             <p style={{ fontSize: "10px", margin: 0 }} className="text-auto-color">
//                                 {org?.company_name}
//                             </p>
//                             <p style={{ fontSize: "10px", margin: 0, width: "340px" }} className="text-auto-color">
//                                 {org?.address}
//                             </p>
//                             <p style={{ fontSize: "10px", margin: 0, width: "340px" }} className="text-auto-color">
//                                 GSTIN NO: {org?.gst_no}
//                             </p>
//                             <p style={{ fontSize: "10px", margin: 0, width: "340px" }} className="text-auto-color">
//                                 MOBILE NO: {org?.mobile}
//                             </p>
//                             <p style={{ fontSize: "10px", margin: 0, width: "340px" }} className="text-auto-color">
//                                 SUPPORT MAIL: {org?.support_mail}
//                             </p>
//                         </div>
//                     </div>
//                     <div>
//                         <div className="d-flex justify-content-center align-items-center ">
//                             <div className="fs-6" heading="Tax Invoice" />
//                         </div>
//                         <div style={{ fontSize: "12px" }} className="text-auto-color row my-1 text-left">
//                             <div className="col-6 my-3">
//                                 <h6 className="fw-bold">Billing Address Detail</h6>
//                                 <div>{OrderSigledata.billing_company_name}</div>
//                                 <div>{OrderSigledata.name}</div>
//                                 <div>{OrderSigledata.email}</div>
//                                 <div>{OrderSigledata.billing_address.created}</div>
//                                 <div>{OrderSigledata.billing_address.address}</div>
//                                 <div>{OrderSigledata.billing_address.address_type}</div>
//                                 <div>{OrderSigledata.billing_pincode}</div>
//                                 <div>{OrderSigledata.billing_gst_no}</div>
//                             </div>
//                             <div className="col-6 my-3">
//                                 <h6 className="fw-bold">Shipping Address</h6>
//                                 <div>{OrderSigledata.shipping_company_name}</div>
//                                 <div>{OrderSigledata.user.first_name}</div>
//                                 <div>{OrderSigledata.user.last_name}</div>
//                                 <div>{OrderSigledata.user.email}</div>
//                                 <div>{OrderSigledata.shipping_address.address}</div>
//                                 <div>{OrderSigledata.shipping_address.address_type}</div>
//                                 <div>{OrderSigledata?.shipping_pincode || ""}</div>
//                                 <div>{OrderSigledata.shipping_address.gst_no}</div>
//                             </div>
//                         </div>
//                         <div style={{ fontSize: "10px" }} className="row text-auto-color">
//                             <div className="col-12">
//                                 <table style={{ margin: 0 }} className="table table-bordered table-auto-color">
//                                     <thead style={{ background: "#343A40" }}>
//                                         <tr>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 Part No with Desc
//                                             </th>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 Review
//                                             </th>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 hsn/sac
//                                             </th>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 Unit Value
//                                             </th>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 Qty
//                                             </th>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 uom
//                                             </th>
//                                             <th className="text-light" style={{ fontSize: "12px", padding: "4px" }} height="10">
//                                                 Extd wo Tax
//                                             </th>
//                                         </tr>
//                                     </thead>
//                                     <tbody style={{ lineHeight: "5px" }}>
//                                         {OrderSigledata.order_parts.map((item) => (
//                                             <tr key={item.id}>
//                                                 <td className="text-auto-color" style={{ fontSize: "10px" }}>
//                                                     {item.part_number.part_number}
//                                                 </td>
//                                                 <td className="text-auto-color">
//                                                     {renderStars(item.part_number)}
//                                                 </td>
//                                                 <td className="text-auto-color">
//                                                     {item.part_number.gst_itm?.hsn_or_sac}
//                                                 </td>
//                                                 <td className="text-auto-color">
//                                                     {item.total}
//                                                 </td>
//                                                 <td className="text-auto-color">
//                                                     {item.qty}
//                                                 </td>
//                                                 <td className="text-auto-color">
//                                                     {item.part_number.uom.name}
//                                                 </td>
//                                                 <td className="text-auto-color">
//                                                     {item.total_payable}
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                         <tr>
//                                             <td className="text-auto-color" colSpan="5"></td>
//                                             <td className="text-auto-color">
//                                                 Shipping & Handling
//                                             </td>
//                                             <td className="text-auto-color">
//                                                 0.00
//                                             </td>
//                                         </tr>
//                                         {OrderSigledata.order_parts.map((item) => (
//                                             <tr key={`gross-${item.id}`}>
//                                                 <td className="text-auto-color" colSpan="5"></td>
//                                                 <td className="text-auto-color">Gross Total</td>
//                                                 <td className="text-auto-color">
//                                                     {item.total_payable}
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {showModal && (
//                 <div className="modal" style={{ display: 'block' }}>
//                     <div className="modal-dialog">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title">Rate Part</h5>
//                                 <button type="button" className="close" onClick={() => setShowModal(false)}>
//                                     <span>&times;</span>
//                                 </button>
//                             </div>
//                             <div className="modal-body">
//                                 <div className="rating">
//                                     {[1, 2, 3, 4, 5].map((star) => (
//                                         <i
//                                             key={star}
//                                             className={`fas fa-star ${selectedRating >= star ? 'text-warning' : ''}`}
//                                             onClick={() => handleRatingChange(star)}
//                                         ></i>
//                                     ))}
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="comment">Comment:</label>
//                                     <textarea
//                                         id="comment"
//                                         className="form-control"
//                                         value={comment}
//                                         onChange={handleCommentChange}
//                                     ></textarea>
//                                 </div>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
//                                     Close
//                                 </button>
//                                 <button type="button" className="btn text-light" style={{backgroundColor: '#1B6392'}} onClick={handleSubmitReview}>
//                                     Submit
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

// export default OrderSingledata;

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import "./OrderSingledata.css";

const OrderSingledata = ({ OrderSigledata }) => {
  const jsonString = JSON.parse(localStorage.getItem("LoginResponse"));

  // const { org, user_id } = JSON.parse(localStorage.getItem('LoginResponse'));
  const { org, user_id } = jsonString;
  const orgid = localStorage.getItem("orgid");
  const [orgInfo, setOrgInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);
  const [comment, setComment] = useState("");
  const [currentPart, setCurrentPart] = useState(null);
  const componentRef = useRef();
  const [GST, setGST] = useState();
  const [isPrintMode, setIsPrintMode] = useState(false);

  useEffect(() => {
    const orgDetailsUrl = `https://admin.inventab.io/api/v1/org/get/all/org/details/?id=${orgid}`;
    axios
      .get(orgDetailsUrl)
      .then((response) => {
        setOrgInfo(response.data.results[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [orgid]);

  // const handlePrint = useReactToPrint({
  //     content: () => componentRef.current,
  // });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setIsPrintMode(true);
    },
    onAfterPrint: () => {
      setIsPrintMode(false);
    },
  });

  const handleStarClick = (part) => {
    setCurrentPart(part);
    setShowModal(true);
  };

  const handleRatingChange = (rating) => {
    setSelectedRating(rating);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitReview = () => {
    const payload = {
      parts: currentPart.id,
      rating: selectedRating,
      comment: comment,
      rating_user: user_id,
    };

    axios
      .post(`https://admin.inventab.io/api/v1/parts/create/part-rating/`, payload)
      .then((response) => {
        console.log("Review submitted successfully:", response.data);
        setShowModal(false);
        setSelectedRating(0);
        setComment("");
      })
      .catch((error) => {
        console.error("Error submitting review:", error);
      });
  };

  const renderStars = (part) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className="fas fa-star text-warning"
          onClick={() => handleStarClick(part)}
        ></i>
      );
    }
    return stars;
  };

  useEffect(() => {
    if (OrderSigledata) {
      const gst_percent =
        OrderSigledata?.order_parts?.[0]?.part_number?.gst_itm?.country_gst?.[0]
          ?.gst_percent ?? 0;
      setGST(gst_percent);
    }
  }, [OrderSigledata]);

  // Handle loading states
  if (!OrderSigledata || !orgInfo) {
    return null;
  }

  if (!OrderSigledata || !orgInfo) {
    return null;
  }

  // console.log(GST, "gst")
  // console.log(OrderSigledata, "Order Sigle Data");

  return (
    <>
      <div className="container">
        <div className="print-area">
          <div className="pb-3 pr-4 d-flex justify-content-end">
            <button
              style={{ border: "1px solid rgba(128, 113, 113, 0.2)" }}
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
          <div className="prints" ref={componentRef}>
            <div className="order d-flex justify-content-center pt-2 pb-2">
              <div className="text-dark">
                <strong>TAX INVOICE</strong>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div
                className="d-flex flex-column text-left"
                style={{ fontSize: "15px" }}
              >
                {orgInfo.logo && (
                  <img
                    src={orgInfo.logo}
                    alt="logo"
                    style={{ width: "200px" }}
                  />
                )}
                <p style={{ margin: 0 }} className="text-auto-color">
                  {org?.company_name}
                </p>
                <p
                  style={{ margin: 0, width: "340px" }}
                  className="text-auto-color"
                >
                  {org?.address}
                </p>
                <p
                  style={{ margin: 0, width: "340px" }}
                  className="text-auto-color"
                >
                  GSTIN NO: {org?.gst_no}
                </p>
                <p
                  style={{ margin: 0, width: "340px" }}
                  className="text-auto-color"
                >
                  MOBILE NO: {org?.mobile}
                </p>
                <p
                  style={{ margin: 0, width: "340px" }}
                  className="text-auto-color"
                >
                  SUPPORT MAIL: {org?.support_mail}
                </p>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center align-items-center ">
                <div className="fs-6" heading="Tax Invoice" />
              </div>


              <div
                style={{ fontSize: "15px" }}
                className="text-auto-color row my-1 text-left"
              >
                <div className="col-12 col-md-6 my-3">
                  <h6 className="fw-bold">Billing Address Detail</h6>
                  <div>{OrderSigledata?.billing_company_name}</div>
                  <div>{OrderSigledata?.name}</div>
                  <div>{OrderSigledata?.email}</div>
                  {/* <div>{OrderSigledata.billing_address.created}</div> */}
                  <div>{OrderSigledata?.billing_address?.address}</div>
                  {/* <div>{OrderSigledata.billing_address.address_type}</div> */}
                  <div>{OrderSigledata?.billing_pincode}</div>
                  <div>{OrderSigledata?.billing_gst_no}</div>
                </div>
                <div className=" col-12 col-md-6 my-3">
                  <h6 className="fw-bold">Shipping Address</h6>
                  <div>{OrderSigledata?.shipping_company_name} </div>
                  <div>
                    {OrderSigledata?.user?.first_name}{" "}
                    {OrderSigledata?.user?.last_name}
                  </div>
                 
                  <div>{OrderSigledata?.user?.email}</div>
                  <div>{OrderSigledata?.shipping_address?.address}</div>
                 
                  <div>{OrderSigledata?.shipping_pincode || ""}</div>
                    <div>{OrderSigledata?.shipping_address?.gst_no}</div>
                </div>
              </div>

              <div style={{ fontSize: "15px" }} className="row text-auto-color">
                <div className="col-12">
                  <table
                    style={{ margin: 0, width: 'auto',border:0 }} // Set width to 100%
                    className="table table-bordered table-auto-color table-responsive" // Added table-responsive class
                  >
                    <thead style={{ background: "#343A40" }} className="w-100">
                      <tr className="w-100">
                        <th
                          className="text-light"
                          style={{ padding: "4px" }}
                          height="10"
                        >
                          Part No with Desc
                        </th>
                        <th
                          className="text-light review-column "
                          style={{ padding: "4px" }}
                          height="10"
                        >
                          Review
                        </th>
                        <th
                          className="text-light"
                          style={{ padding: "4px" }}
                          height="10"
                        >
                          hsn/sac
                        </th>
                        <th
                          className="text-light"
                          style={{ padding: "4px" }}
                          height="10"
                        >
                          Unit Value
                        </th>
                        <th
                          className="text-light "
                          style={{ padding: "4px" }}
                          height="10"
                        >
                          Qty
                        </th>
                        <th
                          className="text-light "
                          style={{ padding: "4px" }}
                          height="10"
                        >
                          uom
                        </th>
                        <th
                          className="text-light "
                          style={{ fontSize: "12px", padding: "4px" }}
                          height="10"
                        >
                          Extd wo Tax
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ lineHeight: "5px" }} className="w-100">
                      {OrderSigledata.order_parts.map((item) => (
                        <tr key={item.id} className="text-left">
                          <td className="text-auto-color">
                            {item.part_number.part_number}
                          </td>
                          <td
                            className="text-auto-color review-column"
                            style={{ cursor: "pointer" }}
                          >
                            {renderStars(item.part_number)}
                          </td>
                          <td className="text-auto-color">
                            {item.part_number.gst_itm?.hsn_or_sac}
                          </td>
                          <td className="text-auto-color">{item.total}</td>
                          <td className="text-auto-color">{item.qty}</td>
                          <td className="text-auto-color">
                            {item.part_number.uom.name}
                          </td>
                          <td className="text-auto-color">
                            {item.total_payable}
                          </td>
                        </tr>
                      ))}

                   
                      {(() => {
                        const totalCGST = OrderSigledata.order_parts
                          .reduce(
                            (acc, item) => acc + parseFloat(item.cgst || 0),
                            0
                          )
                          .toFixed(2);
                        const totalSGST = OrderSigledata.order_parts
                          .reduce(
                            (acc, item) => acc + parseFloat(item.sgst || 0),
                            0
                          )
                          .toFixed(2);

                        return (
                          <>
                            <tr className="text-left">
                              <td className="text-auto-color " colSpan="5"></td>
                              <td className="text-auto-color">CGST(9%)</td>
                              <td className="text-auto-color">{totalCGST}</td>
                            </tr>
                            <tr className="text-left">
                              <td className="text-auto-color" colSpan="5"></td>
                              <td className="text-auto-color">SGST(9%)</td>
                              <td className="text-auto-color">{totalSGST}</td>
                            </tr>
                          </>
                        );
                      })()}

                      <tr className="text-left">
                        <td className="text-auto-color" colSpan="5"></td>
                        <td className="text-auto-color">Shipping & Handling</td>
                        <td className="text-auto-color">0.00</td>
                      </tr>

                      <tr className="text-left">
                        <td className="text-auto-color" colSpan="5"></td>
                        <td className="text-auto-color">Gross Total</td>

                        <td className="text-auto-color">
                          {OrderSigledata.order_parts
                            .reduce(
                              (acc, item) =>
                                acc + parseFloat(item.total_payable || 0),
                              0
                            )
                            .toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Rate Part</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <i
                      key={star}
                      className={`fas fa-star ${
                        selectedRating >= star ? "text-warning" : ""
                      }`}
                      onClick={() => handleRatingChange(star)}
                    ></i>
                  ))}
                </div>
                <div className="form-group">
                  <label htmlFor="comment">Comment:</label>
                  <textarea
                    id="comment"
                    className="form-control"
                    value={comment}
                    onChange={handleCommentChange}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn text-light"
                  style={{ backgroundColor: "#1B6392" }}
                  onClick={handleSubmitReview}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSingledata;
