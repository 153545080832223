import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { UserContext } from './Api/context/AppContext';


const CardSummary = ({ totalCartPrice, data, handleSubmit, }) => {

  const { HandleFormShow, showForm } = useContext(UserContext)
  const queryClient = useQueryClient();

  const totalTax = data.reduce((sum, item) => {
    const gstPercent = item.part_number.gst_itm.country_gst[0].gst_percent;
    const itemPrice = item.qty * item.part_number.mrp; // Assuming 'mrp' is the price of the item
    return sum + (itemPrice * gstPercent / 100);
  }, 0);

  console.log(data, "shopping data ______________")

  const handleCheckoutClick = () => {
    const productsInfo = data.map((item) => ({
      user: item.user.id,
      qty: item.qty,
      part_number: item.part_number.id,
    }));
    

    handleSubmit(totalCartPrice, productsInfo);
  };

  return (
    <>

      <div className=" mb-5">
        <div style={{border: '2px solid rgba(128, 113, 113, 0.2'}}>
        <div className="card-header text-start">
          <h4 className="font-weight-semi-bold  m-0 text-dark">Cart Total</h4>
        </div>
        <div className="card-body mt-2">
          <div className="d-flex justify-content-between pt-1">
            <h5 className="font-weight-normal mx-3 text-muted">Sub total</h5>
            <h5 className="font-weight-normal mx-3">
              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalCartPrice)}
            </h5>
          </div>
          <div className='d-flex justify-content-between mb-2 pt-1'>
            <h5 className="font-weight-normal mx-3 text-muted">Shipping</h5>
            <h5 className="font-weight-normal mx-3">
              Free
            </h5>
          </div>
          <div className='d-flex justify-content-between mb-2 pt-1'>
            <h5 className="font-weight-normal mx-3 text-muted">Discount</h5>
            <h5 className="font-weight-normal mx-3">
              0.00
            </h5>
          </div>
          <div className='d-flex justify-content-between mb-2 pt-1'>
            <h5 className="font-weight-normal mx-3 text-muted">Tax</h5>
            <h5 className="font-weight-normal mx-3">
              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalTax)}
            </h5>
          </div>
          <div className="d-flex justify-content-between">{/* Additional information goes here */}</div>
        </div>
        <div className="card-footer border-secondary bg-transparent">
          <div className="d-flex justify-content-between mt-2">
            <h5 className="font-weight-bold text-dark">Total</h5>
            <h5 className="font-weight-bold text-dark">
              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalCartPrice)}
            </h5>
          </div>
          {
            showForm ? <button className="btn btn-block my-3 py-3 text-white font-weight-semi-bold btn-bounce-on-hover" onClick={handleCheckoutClick} style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)' }}>
              <i class="fas fa-rupee-sign fa-lg"></i> Pay Now
            </button>
              :
              <button className="btn btn-block my-3 py-3 text-white font-weight-semi-bold btn-bounce-on-hover rounded" style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)' }} onClick={HandleFormShow}>
                <i className="fas fa-bolt"></i> Proceed To Checkout
              </button>
          }
          </div>
          </div>

          {/* <form className="mt-4" action="" style={{border: '2px solid rgba(128, 113, 113, 0.2'}}>
            <div className="input-group">
              <input type="text" className="form-control p-4" placeholder="Coupon Code" />
            </div>
            <div className="col-md-6 form-group">
              <label>E-mail</label>
              <input
                className="form-control"
                type="text"
                placeholder="example@email.com"
              />
            </div>
            <div className="input-group-append m-3">
              <button className="btn text-white footerbg" >Apply Coupon</button>
            </div>
          </form> */}

        
      </div>
    </>
  );
};

export default CardSummary;


// import React, { useContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import { useQueryClient } from 'react-query';
// import { UserContext } from './Api/context/AppContext';

// const CardSummary = ({ totalCartPrice: initialTotalCartPrice, data, handleSubmit }) => {
//   const { HandleFormShow, showForm } = useContext(UserContext);
//   const queryClient = useQueryClient();
//   const [totalCartPrice, setTotalCartPrice] = useState(initialTotalCartPrice);

//   useEffect(() => {
//     const totalTax = data.reduce((sum, item) => {
//       const gstPercent = item.part_number.gst_itm.country_gst[0].gst_percent;
//       const itemPrice = item.qty * item.part_number.mrp; // Assuming 'mrp' is the price of the item
//       return sum + (itemPrice * gstPercent / 100);
//     }, 0);

//     const totalWithTax = initialTotalCartPrice + totalTax;
//     setTotalCartPrice(totalWithTax);
//   }, [data, initialTotalCartPrice]);

//   const handleCheckoutClick = () => {
//     const productsInfo = data.map((item) => ({
//       user: item.user.id,
//       qty: item.qty,
//       part_number: item.part_number.id,
//     }));

//     handleSubmit(totalCartPrice, productsInfo);
//   };

//   return (
//     <>
//       <div className="mb-5">
//         <div style={{ border: '2px solid rgba(128, 113, 113, 0.2)' }}>
//           <div className="card-header text-start">
//             <h4 className="font-weight-semi-bold m-0 text-dark">Cart Total</h4>
//           </div>
//           <div className="card-body mt-2">
//             <div className="d-flex justify-content-between pt-1">
//               <h5 className="font-weight-normal mx-3 text-muted">Sub total</h5>
//               <h5 className="font-weight-normal mx-3">
//                 {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(initialTotalCartPrice)}
//               </h5>
//             </div>
//             <div className='d-flex justify-content-between mb-2 pt-1'>
//               <h5 className="font-weight-normal mx-3 text-muted">Shipping</h5>
//               <h5 className="font-weight-normal mx-3">
//                 Free
//               </h5>
//             </div>
//             <div className='d-flex justify-content-between mb-2 pt-1'>
//               <h5 className="font-weight-normal mx-3 text-muted">Discount</h5>
//               <h5 className="font-weight-normal mx-3">
//                 0.00
//               </h5>
//             </div>
//             <div className='d-flex justify-content-between mb-2 pt-1'>
//               <h5 className="font-weight-normal mx-3 text-muted">Tax</h5>
//               <h5 className="font-weight-normal mx-3">
//                 {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalCartPrice - initialTotalCartPrice)}
//               </h5>
//             </div>
//             <div className="d-flex justify-content-between">{/* Additional information goes here */}</div>
//           </div>
//           <div className="card-footer border-secondary bg-transparent">
//             <div className="d-flex justify-content-between mt-2">
//               <h5 className="font-weight-bold text-dark">Total</h5>
//               <h5 className="font-weight-bold text-dark">
//                 {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalCartPrice)}
//               </h5>
//             </div>
//             {
//               showForm ? (
//                 <button className="btn btn-block my-3 py-3 text-white font-weight-semi-bold btn-bounce-on-hover" onClick={handleCheckoutClick} style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)' }}>
//                   <i className="fas fa-rupee-sign fa-lg"></i> Pay Now
//                 </button>
//               ) : (
//                 <button className="btn btn-block my-3 py-3 text-white font-weight-semi-bold btn-bounce-on-hover rounded" style={{ backgroundColor: 'rgba(248, 138, 28, 0.928)' }} onClick={HandleFormShow}>
//                   <i className="fas fa-bolt"></i> Proceed To Checkout
//                 </button>
//               )
//             }
//           </div>
//         </div>

//         <form className="mt-4" style={{ border: '2px solid rgba(128, 113, 113, 0.2)' }}>
//           <div className="input-group">
//             <input type="text" className="form-control p-4" placeholder="Coupon Code" />
//           </div>
//           <div className="col-md-6 form-group">
//             <label>E-mail</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="example@email.com"
//             />
//           </div>
//           <div className="input-group-append m-3">
//             <button className="btn text-white footerbg">Apply Coupon</button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default CardSummary;


