import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import AddAddressModal from '../AddAddressModal';
import ShoppingCart from '../ShoppingCart';
import { UserContext } from '../Api/context/AppContext';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    appearance: 'none',
    width: '16px',
    height: '16px',
    border: '2px solid rgba(248, 138, 28, 0.928)',
    borderRadius: '3px',
    position: 'relative',
    cursor: 'pointer',
  },
  checkboxChecked: {
    backgroundColor: 'rgba(248, 138, 28, 0.928)',
  },
  label: {
    marginLeft: '8px',
    marginTop: '4px',
  },
};

const OrderForm = ({ totalCartPrice, errors, handleInputChange, formData, usedetails }) => {
  const [selectedBillingAddress, setSelectedBillingAddress] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isNewAddressChecked, setIsNewAddressChecked] = useState(false);
  const [addressTypes, setAddressTypes] = useState([]);
  const [newAddress, setNewAddress] = useState({
    address: '',
    pincode: '',
    gst_no: '',
    address_type: ''
  });
  // console.log(formData, "cvbnmlkjhgfdfghj")


  useEffect(() => {
    async function fetchAddressTypes() {
      try {
        const response = await axios.get('https://admin.inventab.io/api/v1/organizations/fetch/address/type');
        if (response.status === 200) {
          setAddressTypes(response.data.results || []);
        } else {
          throw new Error('Failed to fetch address types');
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchAddressTypes();
  }, []);
  
  const handleBillingAddressChange = (fieldName, value) => {
    handleInputChange(fieldName, value);
    setSelectedBillingAddress(value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsNewAddressChecked(false);
  };

  const handleCheckboxChange = (e) => {
    setIsNewAddressChecked(e.target.checked);
    if (e.target.checked) {
      setShowModal(true);
    }
  };

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleNewAddressSubmit = async () => {
    try {
      const loginResponse = JSON.parse(localStorage.getItem('loginResponse'));
      const companyId = loginResponse?.data?.org?.id;

      const response = await axios.post(
        'https://admin.inventab.io/api/v1/organizations/new/org/address/',
        {
          org: companyId,
          address_type: newAddress.address_type,
          pincode: newAddress.pincode,
          gst_no: newAddress.gst_no,
          address: newAddress.address,
          country: null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Handle successful address addition (e.g., update address list)
        setIsNewAddressChecked(false); // Optionally close the form
      } else {
        throw new Error('Failed to add address');
      }
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message)
    }
  };

  const selectedBillingDetails = usedetails.find(item => item.id === selectedBillingAddress);
  const displayGstNumber = selectedBillingDetails ? selectedBillingDetails.gst_no : '';

  return (
    <>
      <div className="mb-4">
        <h4 className="font-weight-semi-bold mb-4">Billing Information</h4>
        {/* Your form fields */}
        <div className="row">
          <div className="col-md-6 form-group">
            <label>Billing Company Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="John"
              onChange={(e) => handleInputChange('billing_company_name', e.target.value)}
              value={formData.billing_company_name}
            />
            {/* <small className="text-danger">{errors.billing_company_name}</small> */}
          </div>
          <div className="col-md-6 form-group d-none ">
            <label>Billing Pincode</label>
            <input
              className="form-control"
              type="text"
              placeholder="Pincode"
              value={formData.billing_pincode}
              onChange={(e) => handleInputChange('billing_pincode', e.target.value)}
            />
            {/* <small className="text-danger">{errors.billing_company_name}</small> */}
          </div>
          <div className="col-md-6 form-group d-none">
            <label>Billing GST No</label>
            <input
              className="form-control"
              type="text"
              placeholder="GST No"
              // value={formData.billing_gst_no}
              value={displayGstNumber}
              // value={displayGstNumber}
              onChange={(e) => handleInputChange('billing_gst_no', e.target.value)}
            />

            <small className="text-danger">{errors.billing_gst_no}</small>
          </div>

          <div className="col-md-6 form-group d-none">
            <label>Shipping Company Name </label>
            <input
              className="form-control"
              type="text"
              value={formData.shipping_company_name}
              placeholder="Shipping Company"
              onChange={(e) => handleInputChange('shipping_company_name', e.target.value)}
            />
            <small className="text-danger">{errors.shipping_company_name}</small>
          </div>
          <div className="col-md-6 form-group d-none">
            <label>Shipping Pincode</label>
            <input
              className="form-control"
              type="text"
              placeholder="Shipping Pincode"
              value={formData.shipping_pincode}
              onChange={(e) => handleInputChange('shipping_pincode', e.target.value)}
            />
            <small className="text-danger">{errors.shipping_pincode}</small>
          </div>
          <div className="col-md-6 form-group">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
            <small className="text-danger">{errors.name}</small>
          </div>
          <div className="col-md-6 form-group">
            <label>Mobile No</label>
            <input
              className="form-control"
              type="text"
              placeholder="Mobile No"
              value={formData.mobile}
              onChange={(e) => handleInputChange('mobile', e.target.value)}
            />
            <small className="text-danger">{errors.mobile}</small>
          </div>
          <div className="col-md-6 form-group">
            <label>E-mail</label>
            <input
              className="form-control"
              type="text"
              placeholder="example@email.com"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
            <small className="text-danger">{errors.email}</small>
          </div>
        </div>

        <div className='d-flex'>

          <div className="mb-3 mr-2 w-50">
            <label htmlFor="billing_address" className="register-label">Billing Address:</label>
            <select
              id="billing_address"
              name="billing_address"
              value={formData.billing_address}
              onChange={(e) => handleBillingAddressChange('billing_address', e.target.value)}
              className="form-select p-2"
            >
              <option value="">Select a billing address</option>
              {usedetails.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.address}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 w-50 ml-2">
            <label htmlFor="market_place" className="register-label">shipping Address:</label>
            <select
              id="market_place"
              name="market_place"
              onChange={(e) => handleInputChange('shipping_address', e.target.value)}
              className="form-select p-2"
            >
              <option value={formData.shipping_address}>Select a shipping address</option>
              {usedetails.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.address}
                </option>
              ))}
            </select>
          </div>


        </div>
        <div className='row'>
          <div className='col-md-6 w-50'>
            <label>GST No:</label>
            <input
              className="form-control"
              type="text"
              placeholder="GST No"
              value={displayGstNumber}
              disabled
            />
          </div>
        </div>
        <div className='d-flex align-items-center'>  
          <input
            type="checkbox"
            id="custom-checkbox"
            checked={isNewAddressChecked}
            onChange={handleCheckboxChange}
          />
          <label className='ml-2 mt-2'>Not your shipping address ? Add new address</label>
        </div>

        {isNewAddressChecked && (
          <div className="mt-3">
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Address Type</label>
                <select
                  className="form-control"
                  name="address_type"
                  value={newAddress.address_type}
                  onChange={handleNewAddressChange}
                >
                  <option value="">Select Address Type</option>
                  {addressTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label>Address</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={newAddress.address}
                  onChange={handleNewAddressChange}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Pincode</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Pincode"
                  name="pincode"
                  value={newAddress.pincode}
                  onChange={handleNewAddressChange}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>GST Number</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="GST Number"
                  name="gst_no"
                  value={newAddress.gst_no}
                  onChange={handleNewAddressChange}
                />
              </div>
            </div>
            <Button className="btn footerbg text-white" onClick={handleNewAddressSubmit}>
              Add Address
            </Button>
          </div>
        )}

        {/* <AddAddressModal show={showModal} handleClose={handleCloseModal} formData={formData} /> */}
      </div>
    </>


  );
};

export default OrderForm;



