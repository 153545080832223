import React from 'react';

const ViewOrderDetails = ({ OrderSigledata }) => {


    console.log(OrderSigledata, "_______OrderSigledata  OrderSigledata ")


    if (!OrderSigledata) {
        // Handle the case when data is not available
        return null;
    }

    return (
        <>
            <div className='row'>

                <div className='col-lg-6 text-left'>
                    <h5 class="card-title ml-3">Order Id: {OrderSigledata.order_id} </h5>
                    <div class="card-body">

                        <ul class="list-group list-group-flush">
                            <li className="list-group-item text-dark"><strong>Shipping Company Name:</strong>  {OrderSigledata?.shipping_company_name}</li>
                            <li className="list-group-item text-dark"><strong>First Name:</strong> {OrderSigledata?.user?.first_name}</li>
                            <li className="list-group-item text-dark"><strong>Last Name:</strong> {OrderSigledata?.user?.last_name}</li>
                            <li className="list-group-item text-dark"><strong>Email:</strong> {OrderSigledata?.user?.email}</li>
                            <li className="list-group-item text-dark"><strong>Address:</strong> {OrderSigledata?.shipping_address?.address}</li>
                            {/* <li className="list-group-item text-dark"><strong>Address Type:</strong> {OrderSigledata.shipping_address.address_type}</li> */}
                            <li className="list-group-item text-dark"><strong>Pin : </strong> {OrderSigledata?.shipping_pincode || ""}</li>
                        </ul>
                    </div>
                </div>

                <div className='col-lg-6 text-left'>
                    <div class="card-body">

                        <ul class="list-group list-group-flush">
                            <li className="list-group-item text-dark"><strong>Billing Company Name:</strong> {OrderSigledata?.billing_company_name}</li>
                            <li className="list-group-item text-dark"><strong>First Name:</strong> {OrderSigledata?.name}</li>
                            <li className="list-group-item text-dark"><strong>Email:</strong> {OrderSigledata?.email}</li>
                            <li className="list-group-item text-dark"><strong>Mobile:</strong> {OrderSigledata.mobile}</li>
                            <li className="list-group-item text-dark"><strong>date :</strong>{OrderSigledata?.billing_address?.created}</li>
                            <li className="list-group-item text-dark"><strong>Address:</strong> {OrderSigledata?.billing_address?.address}</li>
                            {/* <li className="list-group-item text-dark"><strong>Address Type:</strong> {OrderSigledata.billing_address.address_type}</li> */}
                            <li className="list-group-item text-dark"><strong>Pincode:</strong> {OrderSigledata?.billing_pincode}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewOrderDetails;