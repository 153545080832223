import React, { useState, useEffect } from "react";
import "./Register.css";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import VerifyOtp from "./VerifyOtp";
import register from "./Images/register.jpeg";
import Header from "../Header";
import Footer from "../Footer";
import Select from "react-select";
function RegisterPage() {
  const [organizations, setOrganizations] = useState([]);
  const [marketdata, setMarketData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showECom, setshowECom] = useState(true);
  const [propEmail, setpropEmail] = useState("");
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    market_place: "",
    org: "",
    password: "",
    mobile: "",
  });
  console.log(userData.email);

  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // getOrganizations();
    getMarketPlaceData();
  }, []);

  // const getOrganizations = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://admin.inventab.io/api/v1/organizations/fetch/org/"
  //     );
  //     setOrganizations(response.data.results);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getOrganizations = async (inputValue) => {
    if (!inputValue) return; // Do nothing if input is empty
    console.log("API call initiated with:", inputValue);

    try {
      const response = await axios.get(
        `https://admin.inventab.io/api/v1/org/get/org/details/${inputValue}`
      );
      console.log(response?.data.data, "ssss");
      const userArr =
        response?.data?.data.map((org) => ({
          label: org?.company_name,
          value: org?.id,
          market_place: org?.marketplace?.id,
        })) || [];
      console.log(userArr, "userArr");
      setOrganizations(userArr);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const getMarketPlaceData = async () => {
    try {
      const response = await axios.get(
        "https://admin.inventab.io/api/v1/parts/get/marketplace/"
      );
      setMarketData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });

    setpropEmail(userData.email);
    console.log(propEmail, "propemail");
  };

  // ... (Other code remains the same)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirm_password) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        "https://admin.inventab.io/api/v1/users/new/create/user/",
        userData
      );
      if (response.data) {
        console.log("User Created Successfully", response.data);

        //  localStorage.setItem("Email", userData.email)
        // console.log("____________-use data", userData.email)

        // Reset the form fields
        setUserData({
          first_name: "",
          last_name: "",
          email: "",
          market_place: "",
          org: "",
          password: "",
          mobile: "",
        });

        setshowECom(false);
        // navigate("/otp")
      } else {
        setError("User creation failed. Please check your data.");
      }
    } catch (error) {
      console.error("User creation failed:", error.response.data);
      const emailError = error.response.data.email
        ? error.response.data.email[0]
        : "";
      const marketError = error.response.data.market
        ? error.response.data.market[0]
        : "";
      const orgError = error.response.data.org
        ? error.response.data.org[0]
        : "";
      setError(
        `User creation failed. Please try again later. Email: ${emailError}`
      );
    }
  };

  const handleInput = (inputValue) => {
    console.log(inputValue, "check input");
    if (inputValue && inputValue.trim() !== "") getOrganizations(inputValue); // Check if inputValue is not blank
  };

  return (
    <>
      {/* <div style={{width: '100%',
        padding: '10px', // Adjust as needed
        textAlign: 'center'}}>
          <Header />
        </div>

      {
        showECom ? (
          <div className="register-page bg-light">
            <div className='row'>
            <div className="col-md-6 register-image">
                <img src={register} alt="Register Visual" />
              </div>
            <div className="col-md-6 register-container">
              <form onSubmit={handleSubmit} className="register-form">
                <h2 className='register-title mb-4'>REGISTER</h2>

                <div className='row'>
                <div className="col-md-6 input-group">
                  <label htmlFor="first_name" className="register-label">First Name:</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={userData.first_name}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>

                <div className="col-md-6 input-group">
                  <label htmlFor="last_name" className="register-label">Last Name:</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={userData.last_name}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                </div>

                <div className="input-group">
                  <label htmlFor="email" className="register-label">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                  {error && error.includes('Email') && <p className="register-error">{error.split('Email: ')[1].split(', Market:')[0]}</p>}
                </div>

                <div className='row'>
                <div className="col-md-6 input-group">
                  <label htmlFor="market_place" className="register-label">Market Place:</label>
                  <select
                    id="market_place"
                    name="market_place"
                    value={userData.market_place}
                    onChange={handleInputChange}
                    className="register-input"
                  >
                    <option value="">Select a market place</option>
                    {marketdata.map((item) => (
                      <option key={item.id} value={item.marketplace_name}>
                        {item.marketplace_name}
                      </option>
                    ))}
                  </select>
                  {error && error.includes('Market') && <p className="register-error">{error.split('Market: ')[1].split(', Org:')[0]}</p>}
                </div>

                <div className="col-md-6 input-group">
                  <label htmlFor="org" className="register-label">Organization:</label>
                  <select
                    id="org"
                    name="org"
                    value={userData.org}
                    onChange={handleInputChange}
                    className="register-input"
                  >
                    <option value="">Select an organization</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.company_name}>
                        {org.company_name}
                      </option>
                    ))}
                  </select>
                  {error && error.includes('Org') && <p className="register-error">{error.split('Org: ')[1]}</p>}
                </div>
                </div>

                <div className="input-group">
                  <label htmlFor="mobile" className="register-label">Mobile:</label>
                  <input
                    type="number"
                    id="mobile"
                    name="mobile"
                    value={userData.mobile}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="password" className="register-label">Password:</label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={userData.password}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                  <div className="password-toggle-icon pt-4" onClick={handleTogglePassword}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                </div>

                <div className="input-group">
                  <button type="submit" className="register-button">Register </button>
                </div>

                {error && <p className="register-error">{error}</p>}
              </form>
              <p className="reg_log">Already have an account? <Link to="/login">Sign in</Link></p>
            </div>
            </div>
          </div>
        ) : (
          <VerifyOtp email={propEmail} />
        )
      }
      <div style={{
        marginTop: 'auto',
        width: '100%',
        padding: '10px', // Adjust as needed
        textAlign: 'center'}}>
      <Footer />
      </div> */}

      {/* <Header /> */}
      {showECom ? (
        <div className="mt-4">
          <form onSubmit={handleSubmit} className="register-form">
            {/* <h2 className="register-title mb-4">REGISTER</h2> */}
            <div className="input-group">
              <label htmlFor="first_name" className="register-label">
                First Name:
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={userData.first_name}
                onChange={handleInputChange}
                className="register-input"
              />
            </div>
            <div className="input-group">
              <label htmlFor="last_name" className="register-label">
                Last Name:
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={userData.last_name}
                onChange={handleInputChange}
                className="register-input"
              />
            </div>
            <div className="input-group">
              <label htmlFor="email" className="register-label">
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                className="register-input"
              />
              {error && error.includes("Email") && (
                <p className="register-error">{error.split("Email: ")[1]}</p>
              )}
            </div>
            {/* <div className="input-group">
            <label htmlFor="market_place" className="register-label">
              Market Place:
            </label>
            <select
              id="market_place"
              name="market_place"
              value={userData.market_place}
              onChange={handleInputChange}
              className="register-input"
            >
              <option value="">Select a market place</option>
              {marketdata.map((item) => (
                <option key={item.id} value={item.marketplace_name}>
                  {item.marketplace_name}
                </option>
              ))}
            </select>
          </div> */}
            <div className="input-group">
              <label htmlFor="org" className="register-label">
                Organization:
              </label>
              {/* <select
              id="org"
              name="org"
              value={userData.org}
              onChange={handleInputChange}
              className="register-input"
            >
              <option value="">Select an organization</option>
              {organizations.map((org) => (
                <option key={org.id} value={org.company_name}>
                  {org.company_name}
                </option>
              ))}
            </select> */}

              <Select
                className="w-100"
                title="Client"
                name="org"
                placeholder="Search Organization Here"
                isClearable
                onInputChange={handleInput}
                onChange={(selectedOption) => {
                  setUserData({
                    ...userData,
                    org: selectedOption ? selectedOption.value : "",
                    market_place: selectedOption
                      ? selectedOption?.market_place
                      : "",
                  });
                }}
                options={organizations}
                isSearchable
                styles={{
                  option: (provided) => ({
                    ...provided,
                    fontSize: "12px", // Adjust font size as needed
                    textAlign: "left", // Align text to the left
                  }),
                  control: (provided) => ({
                    ...provided,
                    minHeight: "38px", // Adjust control height if needed
                  }),
                }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="mobile" className="register-label">
                Mobile:
              </label>
              <input
                type="number"
                id="mobile"
                name="mobile"
                value={userData.mobile}
                onChange={handleInputChange}
                className="register-input"
              />
            </div>
            <div className="input-group">
              <label htmlFor="password" className="register-label">
                Password:
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={userData.password}
                onChange={handleInputChange}
                className="register-input"
              />
              <div
                className="password-toggle-icon"
                onClick={handleTogglePassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="confirm_password" className="register-label">
                Confirm Password:
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="confirm_password"
                name="confirm_password"
                value={userData.confirm_password}
                onChange={handleInputChange}
                className="register-input"
              />
              {userData.password && userData.confirm_password && userData.password !== userData.confirm_password && (
                <p className="register-error">Passwords do not match.</p>
              )}
            </div>
            <div className="input-group">
              <button type="submit" className="register-button w-100">
                Register
              </button>
            </div>
            {error && <p className="register-error">{error}</p>}
          </form>
        </div>
      ) : (
        <VerifyOtp email={propEmail} />
      )}
      {/* <Footer /> */}
    </>
  );
}

export default RegisterPage;
