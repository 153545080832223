import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../components/Home.css";
import Footer from "./Footer";
import Categorieslist from "./Categorieslist";
import ProductContainer from "./product/ProductContainer";
import Slider from "./Slider";
import divimgright from "./assets/mktplacedivbanner.jpeg";
import divimgleft from "./assets/mktsmallimage.jpeg";
// import shipping from "./assets/Package.png";
import shipping from "./assets/shipping-enta.png";
// import shipping2 from "./assets/CreditCard.png";
import shipping2 from "./assets/card-enta.png";

// import shipping3 from "./assets/Vector.png";
import shipping3 from "./assets/headphone-enata.png";
import dongle from "./assets/doongleimage.png";
import harness from "./assets/harness.png";
// import nav from "./assets/nav.png";
import nav from "./assets/enta-nav-img.png";

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [marketBanner, setMarketBanner] = useState([]);

  useEffect(() => {
    const apiUrl = `https://admin.inventab.io/api/v1/pipo/get/marketplace/list/?marketplace_name=enata-automotive`;
    axios
      .get(apiUrl)
      .then((response) => {
        let result = response.data.results;
        setData(result);
        setFeatures(result[0].marketplace_hotproducts || []);

        setMarketBanner(result[0].marketplace_banner || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log(features, "features");
  }, [features]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center ">
          {/* ... existing code ... */}

          <div className="col-md-11 text-center mb-3  rounded">
            <div
              className="responsive-background"
              style={{
                backgroundImage: `url(${nav})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "70px",
                width: "100%",
                maxWidth: "100%", // Ensure it doesn't exceed the container width
                aspectRatio: "16 / 9", // Maintain aspect ratio for responsiveness
                display: "flex", // Center the content
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            ></div>
          </div>
        </div>

        <div
          className="row justify-content-center "
          style={{ overflow: "hidden" }}
        >
          <div className="col-12 col-md-4 p-3 d-flex justify-content-center justify-content-md-end">
            <div className="responsive-width">
              <Categorieslist />
            </div>
          </div>
          <div className="col-12 col-md-8 ">
            <Slider marketbenner={marketBanner} />
          </div>
        </div>

        <div className="container  border border-gray mt-5 mb-5">
          <div className="row">
            <div className="col-md-4 d-flex p-3">
              <div className="row">
                <div className="col-md-4  align-items-center">
                  <img src={shipping} alt="Shipping Feature" />
                </div>
              </div>
              <div className="col-md-8 text-align-center text-left">
                <h6>FASTEST DELIVERY</h6>
                <p>Safe and fast delivery</p>
              </div>

              <div
                style={{
                  borderLeft: "1px solid #e0e0e0",
                  height: "calc(100% - 20px)", // Adjust height to account for top and bottom spacing
                  position: "absolute",
                  right: "-10px",
                  top: "10px", // Adjust this value for top space
                  bottom: "10px", // Adjust this value for bottom space
                }}
              ></div>
            </div>

            <div className="col-md-4 d-flex p-3">
              <div className="row">
                <div className="col-md-4  align-items-center">
                  <img src={shipping2} alt="Shipping Feature" />
                </div>
              </div>
              <div className="col-md-8 text-align-center text-left">
                <h6>SECURE PAYMENT</h6>
                <p>Secure payments with us </p>
              </div>

              <div
                style={{
                  borderLeft: "1px solid #e0e0e0",
                  height: "calc(100% - 20px)", // Adjust height to account for top and bottom spacing
                  position: "absolute",
                  right: "-10px",
                  top: "10px", // Adjust this value for top space
                  bottom: "10px", // Adjust this value for bottom space
                }}
              ></div>
            </div>

            <div className="col-md-4 d-flex p-3">
              <div className="row">
                <div className="col-md-4  align-items-center">
                  <img src={shipping3} alt="Shipping Feature" />
                </div>
              </div>
              <div className="col-md-8 text-align-center text-left">
                <h6>SUPPORT 24/7</h6>
                <p>Live support with team </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center mt-4 mb-4 "
          style={{ backgroundColor: "#1B6392" }}
        ></div>

        {/* <div className=" container-fluid mt-3">
          <div className="text-center">
            <h3 className="text-dark p-4">Featured Products</h3>
            <hr className="w-25" />

            <ProductContainer product={features} />
          </div>
        </div> */}

        <div className="container-fluid mt-3">
          <div className="text-center">
            <h3 className="text-dark p-4">Featured Products</h3>
            <hr className="w-25 w-md-25" /> {/* Updated for responsiveness */}
            <ProductContainer product={features} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
