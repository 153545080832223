import React, { useState } from "react";
import "./history.css";
import OrderSingledata from "./orders/OrderSingledata";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import ViewOrderDetails from "./orders/ViewOrderDetails";

// const History = ({ data, error, isLoading, SingleorderData }) => {
//     const [selectedOrder, setSelectedOrder] = useState(null);

//     const handleViewOrderDetails = (orderId) => {
//         const order = data.find(item => item.id === orderId);
//         setSelectedOrder(order);
//     };

//     const formatDate = (dateTimeString) => {
//         const options = {
//             year: 'numeric',
//             month: 'short',
//             day: 'numeric',
//             hour: '2-digit',
//             minute: '2-digit',
//         };
//         const formattedDate = new Date(dateTimeString).toLocaleString('en-US', options);
//         return formattedDate;
//     };

//     if (isLoading) {
//         return <Loader />;
//     }

//     if (error) {
//         return <p>Error: {error.message}</p>;
//     }

//     // Sort data by created date in descending order
//     const sortedData = data.slice().sort((a, b) => new Date(b.created) - new Date(a.created));
//     console.log(sortedData, "sorteddata");
//     return (
//         <>
//             {selectedOrder ? (
//                 <ViewOrderDetails OrderSigledata={selectedOrder} />
//             ) : (
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-12'>
//                             {sortedData.map((item) => (
//                                 <div className='mb-3' key={item.order_id}>
//                                     <table className="table table-bordered w-100">
//                                         <tbody>
//                                             <tr style={{ backgroundColor: 'rgba(128, 113, 113, 0.2)' }}>
//                                                 {item.order_payment.length > 0 && (
//                                                     <>
//                                                         <td className='text-dark'>
//                                                             <span>Part</span><br />
//                                                             <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
//                                                                 {item.order_parts?.map(part => (
//                                                                     <li key={part.part_number.part_number}>
//                                                                         {part.part_number.part_number}
//                                                                     </li>
//                                                                 ))}
//                                                             </ul>
//                                                         </td>
//                                                         <td className='text-dark'>
//                                                             <span>Amount Paid:</span><br />
//                                                             <p>₹ {item.order_payment[0].amount}</p>
//                                                         </td>
//                                                         <td className='text-dark'>
//                                                             <span>Status</span><br />
//                                                             <span className={`text-${item.order_payment[0].status === "Success" ? 'success' : 'danger'}`}>
//                                                                 {item.order_payment[0].status}
//                                                             </span>

//                                                         </td>
//                                                     </>
//                                                 )}
//                                                 <td className='text-dark'>
//                                                     <span>Deliver To</span><br />
//                                                     {/* <Link style={{ cursor: 'pointer' }} onClick={() => SingleorderData(item.id)}>
//                                                         {item.billing_company_name}
//                                                     </Link> */}
//                                                     {item.billing_company_name}
//                                                 </td>
//                                                 <td className='text-dark'>
//                                                     <span className='title-span'>Date</span><br />
//                                                     <span>{formatDate(item.created)}</span>
//                                                 </td>
//                                                 <td className='text-dark' style={{ fontSize: '13px' }}>
//                                                     <span>Order Id: {item.order_id}</span><br />
//                                                     <Link style={{ cursor: 'pointer' }} onClick={() => handleViewOrderDetails(item.id)}>
//                                                         View Order Details
//                                                     </Link>
//                                                     <span> | </span>
//                                                     <Link style={{ cursor: 'pointer' }} onClick={() => SingleorderData(item.id)}>
//                                                         Invoice
//                                                     </Link>
//                                                 </td>
//                                             </tr>
//                                             {/* <tr>

//                                                 <td colSpan="3"></td>
//                                                 <td className='text-dark'>
//                                                     <span>Track Order</span>
//                                                 </td>
//                                             </tr> */}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// }

// export default History;

const History = ({ data, error, isLoading, SingleorderData }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewOrderDetails = (orderId) => {
    const order = data.find((item) => item.id === orderId);
    setSelectedOrder(order);
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  // Sort data by created date in descending order
  const sortedData = data
    .slice()
    .sort((a, b) => new Date(b.created) - new Date(a.created));

  // Function to filter data based on search query
  const filteredData = sortedData.filter((item) => {
    // Check if searchQuery matches orderId, Amount Paid, or part number
    const orderIdMatch = item.order_id
      .toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const amountPaidMatch =
      item.order_payment.length > 0 &&
      item.order_payment[0].amount
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    const partNumberMatch = item.order_parts?.some((part) =>
      part.part_number.part_number
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    return orderIdMatch || amountPaidMatch || partNumberMatch;
  });

  console.log(filteredData, "filtereddata");
  console.log(data, "data)))))))))))))))))))))))))))))");

  return (
    <>
      {selectedOrder ? (
        <ViewOrderDetails OrderSigledata={selectedOrder} />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Search Bar */}
              <div className="d-flex justify-content-center ">
                <span className="input-group-text" style={{ height: "40px" }}>
                  <i className="fa fa-search text-dark "></i>
                </span>

                <input
                  type="text"
                  placeholder="Search by OrderId, Amount Paid, or Part Number"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control mb-3 w-50 border-rounded"
                ></input>
              </div>

              {filteredData.length > 0 ? (
                filteredData.map((item) => (
                  <div className="mb-3 d-flex justify-content-center" key={item.order_id} >
                    {/* <table className="table table-bordered w-100">
                      <tbody>
                        <tr
                          style={{
                            backgroundColor: "rgba(128, 113, 113, 0.2)",
                          }}
                        >
                          {item.order_payment.length > 0 && (
                            <>
                              <td className="text-dark">
                                <span>Part</span>
                                <br />
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {item.order_parts?.map((part) => (
                                    <li key={part.part_number.part_number}>
                                      {part.part_number.part_number}
                                    </li>
                                  ))}
                                </ul>
                              </td>
                              <td className="text-dark">
                                <span>Amount Paid:</span>
                                <br />
                                <p>₹ {item.order_payment[0].amount}</p>
                              </td>
                              <td className="text-dark">
                                <span>Status</span>
                                <br />
                                <span
                                  className={`text-${
                                    item.order_payment[0].status === "Success"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {item.order_payment[0]?.status}
                                </span>
                              </td>
                            </>
                          )}
                          <td className="text-dark">
                            <span>Deliver To</span>
                            <br />
                            {item.billing_company_name}
                          </td>
                          <td className="text-dark">
                            <span className="title-span">Date</span>
                            <br />
                            <span>{formatDate(item.created)}</span>
                          </td>
                          <td
                            className="text-dark"
                            style={{ fontSize: "13px" }}
                          >
                         
                            <button
                              className="btn btn-link text-info"
                              onClick={() => handleViewOrderDetails(item.id)}
                            >
                              Order Id: {item.order_id}
                            </button>
                            {item.order_payment.length > 0 &&
                              item.order_payment[0].status && (
                                <>
                                  <span> | </span>
                                  {item.order_payment[0].status !== "" &&
                                  item.order_payment[0].status !== null ? (
                                    <button
                                      className="btn btn-link text-info"
                                      onClick={() => SingleorderData(item.id)}
                                    >
                                      Invoice
                                    </button>
                                  ) : null}
                                </>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table> */}

                    <table
                      className="table table-bordered w-100 table-responsive"
                      style={{ width: window.innerWidth < 768 ? "100%" : "75%" }}
                    >
                      <tbody >
                        <tr
                          style={{
                            backgroundColor: "rgba(128, 113, 113, 0.2)",
                          }}
                        >
                          <td className="text-dark">
                            <span>Part</span>
                            <br />
                            <ul
                              style={{
                                listStyleType: "none",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {item.order_parts?.length > 0 ? (
                                item.order_parts.map((part) => (
                                  <li key={part.part_number.part_number}>
                                    {part.part_number.part_number}
                                  </li>
                                ))
                              ) : (
                                <li>N/A</li>
                              )}
                            </ul>
                          </td>
                          <td className="text-dark">
                            <span>Amount Paid:</span>
                            <br />
                            <p>
                              ₹{" "}
                              {item.order_payment.length > 0
                                ? item.order_payment[0].amount
                                : "N/A"}
                            </p>
                          </td>
                          <td className="text-dark">
                            <span>Status</span>
                            <br />
                            <span
                              className={`text-${
                                item.order_payment.length > 0 &&
                                item.order_payment[0].status === "Success"
                                  ? "success"
                                  : "danger"
                              }`}
                            >
                              {item.order_payment.length > 0
                                ? item.order_payment[0]?.status
                                : "N/A"}
                            </span>
                          </td>
                          <td className="text-dark">
                            <span>Deliver To</span>
                            <br />
                            {item.billing_company_name || "N/A"}
                          </td>
                          <td className="text-dark">
                            <span className="title-span">Date</span>
                            <br />
                            <span>{formatDate(item.created) || "N/A"}</span>
                          </td>
                          <td
                            className="text-dark"
                            style={{ fontSize: "13px" }}
                          >
                            <button
                              className="btn btn-link text-info"
                              onClick={() => handleViewOrderDetails(item.id)}
                            >
                              Order Id: {item.order_id || "N/A"}
                            </button>
                            {item.order_payment.length > 0 &&
                              item.order_payment[0].status && (
                                <>
                                  <span> | </span>
                                  {item.order_payment[0].status !== "" &&
                                  item.order_payment[0].status !== null ? (
                                    <button
                                      className="btn btn-link text-info"
                                      onClick={() => SingleorderData(item.id)}
                                    >
                                      Invoice
                                    </button>
                                  ) : null}
                                </>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <p>No results found.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default History;
