import React from "react";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../Api/context/AppContext";
import "./ProductCard.css";
import user1 from "../../components/assets/no_image.png";
const ProductCard = ({ item }) => {
  const { user } = useContext(UserContext);
  const [quantity, setQuantity] = useState(1);
  console.log("item:>>", item);
  const incQty = () => {
    setQuantity((prevQty) => prevQty + 1);
  };

  const decQty = () => {
    setQuantity((prevQty) => {
      if (prevQty > 1) {
        return prevQty - 1;
      }
      return prevQty; // Quantity shouldn't go below 1
    });
  };

  //    const itemImage = item.documents.map((itemurl)=>{
  //         console.log(itemurl,"_____image")
  //    })

  // const attachment = itemImage && itemImage.length > 0 ? itemImage[0].attachment : null;

  // const itemPrice = item.part_no;

  const itemImage = item.part_no.default;
  const attachment =
    itemImage && itemImage.attachment ? itemImage.attachment : null;

  const itemImage1 = item.part_no.documents;
  const attachment1 =
    itemImage1 && itemImage1.length > 0
      ? itemImage1[0].attachment.attachment
      : null;

  // console.log(attachment1, "_______--attachment1");

  // ...

  // const finalImage = attachment1 || attachment;
  // const finalImage = item.part_no.documents?.[0]?.attachment || null; // Corrected to access documents from part_no

  const finalImage =
    item.part_no.documents
      .filter((doc) => doc.part_pic === true)
      .map((doc) => doc.attachment) || null; // Get all visible images where part_pic is true

  // const finalImage =
  //   item.part_no.documents.filter((doc) => doc.part_pic === true)?.attachment ||
  //   null;
    console.log(item.part_no.documents,"______item.part_no.documents")
  // Get all visible images where part_pic is true

  // console.log(finalImage, "_______--finalImage");
  useEffect(() => {
    console.log(finalImage, "finalImage");
    console.log(item, "item");
  }, [finalImage]);

  const { addToCard, checkId } = useContext(UserContext);

  // checkId?.map((ckeid)=>{
  //      console.log(ckeid,"__________ckeid")
  // })

  return (
    // <div
    //   className="card mb-4 m-3 p-1 pt-3 border"
    //   style={{ maxHeight: "600px", overflowY: "auto", borderRadius: "0px" }}
    // >
    //   {/* <img
    //     src={finalImage}
    //     className="card-img-top"
    //     alt="Image Not Available"
    //     style={{
    //       // objectFit: 'cover',
    //       height: "200px", // Adjust the height as needed
    //       width: "100%",
    //       border: "1px solid #ddd",
    //     }}
    //   /> */}
    //   {user ? (
    //     <div className="d-flex justify-content-end">
    //       {/* <button className='btn btn-sm footerbg p-2 px-2 rounded'> */}
    //       <Link
    //         to={`/home/singleproduct/${item.part_no.part_number}`}
    //         className="btn btn-sm  "
    //       >
    //         <i className="fas fa-eye" style={{ color: "#1B6392" }}></i>
    //       </Link>
    //       {/* </button> */}
    //       {!checkId.includes(item.part_no.id) ? (
    //         <button
    //           onClick={() => addToCard(item.part_no, quantity)}
    //           className="btn btn-sm  rounded"
    //           // style={{
    //           //     backgroundColor: 'rgba(248, 138, 28, 0.928)'
    //           // }}
    //         >
    //           <i className="fas fa-shopping-cart  text-warning  mr-1"></i>
    //         </button>
    //       ) : (
    //         <Link
    //           to="/home/cart"
    //           className="btn "
    //           style={
    //             {
    //               // backgroundColor: 'rgba(248, 138, 28, 0.928)'
    //             }
    //           }
    //         >
    //           <i className="fas fa-shopping-cart  text-warning  mr-1"></i>
    //           {/* Cart */}
    //         </Link>
    //       )}
    //     </div>
    //   ) : (
    //     <div className="d-flex justify-content-end">
    //     <Link
    //       to={`/home/singleproduct/${item.part_no.part_number}`}
    //       // className="btn btn-info"
    //     >
    //       <i className="fas fa-eye" style={{ color: "#1B6392" }}></i>
    //     </Link>
    //     </div>
    //   )}
    //   {finalImage ? (
    //     <img
    //       src={finalImage}
    //       className="card-img-top"
    //       //   alt="Image Not Available"
    //       style={{
    //         // objectFit: 'cover',
    //         height: "200px", // Adjust the height as needed
    //         width: "100%",
    //         border: "1px solid #ddd",
    //       }}
    //     />
    //   ) : (
    //     <img
    //       src={user1}
    //       className="card-img-top"
    //       alt="Image Not Available"
    //       style={{
    //         objectFit: "cover",
    //         height: "200px", // Adjust the height as needed
    //         width: "100%",
    //         border: "1px solid #ddd",
    //       }}
    //     />
    //   )}
    //   <div className="card-body">
    //     <p
    //       className="card-title"
    //       style={{
    //         wordWrap: "break-word",
    //         whiteSpace: "normal",
    //       }}
    //     >
    //       {item.part_no.short_description}
    //     </p>

    //     <strong>Part No: </strong>
    //     {item?.part_no?.part_number}

    //     <p className="card-text">
    //       <strong>Price:</strong>{" "}
    //       {new Intl.NumberFormat("en-IN", {
    //         style: "currency",
    //         currency: "INR",
    //       }).format(item.part_no.mrp)}
    //     </p>
    //   </div>
    // </div>

    <div
      className="card mb-4 m-3 p-1 pt-3 border "
      style={{ height: "400px", overflow: "hidden", borderRadius: "0px" }}
    >
      {user ? (
        <div className="d-flex justify-content-end flex-wrap">
          {" "}
          {/* Added flex-wrap for responsiveness */}
          <Link
            to={`/home/singleproduct/${item.part_no.part_number}`}
            className="btn btn-sm"
          >
            <i className="fas fa-eye custom-text-color"></i>
          </Link>
          {!checkId.includes(item.part_no.id) ? (
            <button
              onClick={() => addToCard(item.part_no, quantity)}
              
              className="btn btn-sm rounded"
            >
              <i className="fas fa-shopping-cart text-warning mr-1"></i>
            </button>
          ) : (
            <Link to="/home/cart" className="btn">
              <i className="fas fa-shopping-cart text-warning mr-1 custom-text-color"></i>
            </Link>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-end">
          <Link to={`/home/singleproduct/${item.part_no.part_number}`}>
            <i className="fas fa-eye custom-text-color" ></i>
          </Link>
        </div>
      )}
      {finalImage ? (
        <img
          src={finalImage}
          className="card-img-top img-fluid" // Added img-fluid for responsive image
          style={{
            height: "200px", // Adjust the height as needed
            width: "100%",
            border: "1px solid #ddd",
          }}
        />
      ) : (
        <img
          src={user1}
          className="card-img-top img-fluid" // Added img-fluid for responsive image
          alt="Image Not Available"
          style={{
            objectFit: "cover",
            height: "200px", // Adjust the height as needed
            width: "100%",
            border: "1px solid #ddd",
          }}
        />
      )}
      <div className="card-body">
        <p
          className="card-title"
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {item.part_no.short_description}
        </p>

        <strong>Part No: </strong>
        {item?.part_no?.part_number}

        <p className="card-text">
          <strong>Price:</strong>{" "}
          {new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
          }).format(item.part_no.mrp)}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
