import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./Api/context/AppContext";
// import logofoot from "./assets/logowikitek.png"
import logofoot from "../components/assets/enata-logo.png";
import Logo from "./assets/playstore.png";

const Footer = () => {
  const { user, cardCount, handleLogout } = useContext(UserContext);

  const [logo, setLogo] = useState("img/logo-removebg-preview.png");

  useEffect(() => {
    const userNameAmit = localStorage.getItem("emailData");
    const UserPassword = localStorage.getItem("PasswordAmit");

    // console.log("user", userNameAmit);
    // console.log("password", UserPassword);

    if (user) {
      axios
        .post("https://admin.inventab.io/api/v1/accounts/login", {
          username: userNameAmit,
          password: UserPassword,
        })
        .then((response) => {
          const { data } = response;
          if (data && data.success) {
            const organization = data.data.org;
            if (organization && organization.logo) {
              // setLogo(`http://143.244.142.0${organization.logo}`); // Set the logo URL dynamically after successful login
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching login data:", error);
        });
    }
  }, [user]);

  return (
    <>
      {/* <!-- Footer Start --> */}

      {/* <div class="container-fluid footer-container footerbg text-warning mt-1 p-5">
                <div class="row px-xl-5 ">
                    <div class="col-lg-4 col-md-12  pr-3 pr-xl-5">
                        <Link to="/home" class="text-decoration-none">
                            {<img className="logo_head" src={logofoot} alt="No Img" />}
                        </Link>
                        <p className='mt-1 text-white'>wikitek1 is an online marketplace which is dedicated to the automotive aftermarket. we cater to workshops and individual consumers of multiple vehicle segments for their requirements on the spare parts, accessories, tools & equipment and consumables needs.</p>

                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="row d-flex justify-content-around">

                            <div class="col-md-3">
                                <h5 class="font-weight-bold text-white mb-3 "> Customer support</h5>
                                <div class="d-flex flex-column justify-content-start ">
                                    <Link class="text-white mb-1" to="/home/faq"><i class="fa fa-angle-right mr-2"></i>FAQs</Link>
                                    <Link class="text-white mb-1" to="/home/help"><i class="fa fa-angle-right mr-2"></i>privacy policy</Link>
                                
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <h5 class="font-weight-bold text-white mb-3">Contact</h5>
                                <div>
                                    <p className="mb-1 text-white"><i class="fa fa-map-marker-alt text-white mr-3"></i>307,konark plaza,wagholi,pune</p>
                                    <p className="mb-1 text-white"><i class="fa fa-envelope text-white mr-3"></i>support@wikitek.in</p>
                                    <p className="mb-1 text-white"><i class="fa fa-phone-alt text-white mr-3"></i>+91 9028347071</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-2">
                                <h5 class="font-weight-bold text-white mb-3">Download App</h5>
                                <img src={Logo} alt="Play Store logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-top border-light mx-xl-5 py-2 justify-content-center align-items-center">
                    <div class="col-md-6 text-center px-xl-0">
                        <p class="mb-md-0 text-white">
                            &copy; <a class="text-white font-weight-semi-bold" href="http://143.110.190.226/">Inventab MarketPlace</a>. All Rights Reserved | Distributed By <a href="http://www.wikitek.in" target="#" class="text-white">Inventab MarketPlace</a>
                        </p>
                    </div>
                </div>
            </div>
             */}

      <div className="container-fluid footer-container footerbg text-warning mt-1 p-5">
        <div className="row px-xl-5">
          <div className="col-lg-4 col-md-12 pr-3 pr-xl-5">
            <Link to="/home" className="text-decoration-none">
              <img
                className="logo_head img-fluid"
                src={logofoot}
                alt="No Img"
              />
            </Link>
            <p className="mt-1 text-white">
              ENATA Automotive focuses on the development and distribution of
              toolchain that is used by the automotive fraternity for ECU
              communication during vehicle / application / ECU development. We
              bring to you the most value add tools used for testing related to
              vehicle Diagnostics, In vehicle networking, ECU measurement &amp;
              calibration and ECU Reprogramming. The standard toolchain that is
              available off the shelf takes into account most of the protocols
              used in the automotive world. Our toolchain can also be customized
              based on requirements of our customers. So, book a demo with us
              today and experience our toolchain
            </p>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="row d-flex justify-content-around">
              <div className="col-md-3">
                <h5 className="font-weight-bold text-white mb-3">
                  Customer support
                </h5>
                <div className="d-flex flex-column justify-content-start">
                  <Link className="text-white mb-1" to="/home/faq">
                    <i className="fa fa-angle-right mr-2"></i>FAQs
                  </Link>
                  <Link className="text-white mb-1" to="/home/help">
                    <i className="fa fa-angle-right mr-2"></i>privacy policy
                  </Link>
                </div>
              </div>
              <div className="col-md-3 mb-2">
                <h5 className="font-weight-bold text-white mb-3">Contact</h5>
                <div>
                  <p className="mb-1 text-white">
                    <i className="fa fa-map-marker-alt text-white mr-3"></i>307,
                    konark plaza, wagholi, pune
                  </p>
                  <p className="mb-1 text-white">
                    <i className="fa fa-envelope text-white mr-3"></i>
                    support.ea@autopeepal.com
                  </p>
                  <p className="mb-1 text-white">
                    <i className="fa fa-phone-alt text-white mr-3"></i>+91
                    9028347071
                  </p>
                </div>
              </div>
              <div className="col-md-3 mb-2">
                <h5 className="font-weight-bold text-white mb-3">
                  Download App
                </h5>
                <img className="img-fluid" src={Logo} alt="Play Store logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="row border-top border-light mx-xl-5 py-2 justify-content-center align-items-center">
          <div className="col-md-6 text-center px-xl-0">
            <p className="mb-md-0 text-white">
              &copy;{" "}
              <a
                className="text-white font-weight-semi-bold"
                href="http://143.110.190.226/"
              >
                Inventab MarketPlace
              </a>
              . All Rights Reserved | Distributed By{" "}
              <a href="http://www.wikitek.in" target="#" className="text-white">
                Inventab MarketPlace
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
